import React, { useState } from "react";
import ReactECharts from "echarts-for-react";
import { Accordion, Dropdown, Checkbox } from "flowbite-react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { AACNCoreCompetencies, AACNDomains, AACNSubCompetencies } from "../../utils/ScriptingEnumLibrary";
import useGrades from "../../hooks/useGrades";
import useCourses from "../../hooks/useCourses";
import useUsers from "../../hooks/useUsers";
import useAuth from "../../hooks/useAuth";
import Searchbar from "../shared/Searchbar";

const AANCCoreCompetencies = () => {
    const [selectedStudentId, setSelectedStudentId] = useState(null);
    const [selectedCourseId, setSelectedCourseId] = useState(null);

    const { grades } = useGrades();
    const { courses, getCourseById } = useCourses();
    const { users, getUserByID } = useUsers();

    const { isStudent } = useAuth();

    const [searchTermStudents, setSearchTermStudents] = useState('');
    const [searchTermCourses, setSearchTermCourses] = useState('');

    const filteredStudents = users.filter(student =>
        `${student.firstName} ${student.lastName}`.toLowerCase().includes(searchTermStudents.toLowerCase())
    );

    const filteredCourses = courses.filter(course =>
        course.name.toLowerCase().includes(searchTermCourses.toLowerCase())
    );

    const filteredGrades = grades.filter((g) => (g.userId === selectedStudentId || !selectedStudentId) && (g.courseId === selectedCourseId || !selectedCourseId));

    const calculateAACNScores = () => {
        const scores = {}; // To store scores for domains, core competencies, and sub-competencies.

        // Initialize scores structure based on AACNSubCompetencies
        for (const [domainId, coreCompetencies] of Object.entries(AACNSubCompetencies)) {
            scores[domainId] = {
                coreCompetencies: {},
                domainScore: 0,
            };

            for (const [coreId, subCompetencies] of Object.entries(coreCompetencies)) {
                scores[domainId].coreCompetencies[coreId] = {
                    subCompetencies: {},
                    coreScore: 0,
                };

                for (const subId of Object.values(subCompetencies)) {
                    scores[domainId].coreCompetencies[coreId].subCompetencies[subId] = 0;
                }
            }
        }

        // Step 1: Calculate percentage score for each sub-competency
        for (const grade of filteredGrades) {
            const AACNField = grade.AACN || {};

            for (const [domainId, domainData] of Object.entries(AACNField)) {
                if (!scores[domainId]) continue;

                for (const [coreId, achievedSubCompetencies] of Object.entries(domainData)) {
                    if (!scores[domainId].coreCompetencies[coreId]) continue;

                    for (const subId of achievedSubCompetencies) {
                        if (scores[domainId].coreCompetencies[coreId].subCompetencies[subId] !== undefined) {
                            scores[domainId].coreCompetencies[coreId].subCompetencies[subId] += 1;
                        }
                    }
                }
            }
        }

        // Convert sub-competency scores to percentages
        for (const [domainId, domainData] of Object.entries(scores)) {
            for (const [coreId, coreData] of Object.entries(domainData.coreCompetencies)) {
                const subCompetencyCount = Object.keys(coreData.subCompetencies).length;
                let coreTotal = 0;

                for (const [subId, subScore] of Object.entries(coreData.subCompetencies)) {
                    const percentage = Math.round((subScore / (isStudent ? 1.0 : users.length)) * 100);
                    coreData.subCompetencies[subId] = percentage;
                    coreTotal += percentage;
                }

                // Step 2: Calculate core competency score as average of its sub-competencies
                coreData.coreScore = subCompetencyCount > 0 ? Math.round(coreTotal / subCompetencyCount) : 0;
            }

            // Step 3: Calculate domain score as average of its core competencies
            const coreCompetencyCount = Object.keys(domainData.coreCompetencies).length;
            let domainTotal = 0;

            for (const coreData of Object.values(domainData.coreCompetencies)) {
                domainTotal += coreData.coreScore;
            }

            domainData.domainScore = coreCompetencyCount > 0 ? Math.round(domainTotal / coreCompetencyCount) : 0;
        }

        return scores;
    };

    const results = calculateAACNScores();

    const getCompetencyChartOptions = () => {
        const chartData = Object.keys(AACNDomains).map((domain, index) => {
            const result = results[index]?.domainScore || 0;
            return {
                domain: `Domain ${(index + 1)}: ${domain}`,
                Percentage: result
            }
        }).reverse();

        return {
            tooltip: {
                trigger: "item",
                formatter: (params) => `${params.data.domain}: ${params.value.Percentage}%`,
            },
            dataset: { source: chartData },
            grid: {
                left: "2%",
                right: "2%",
                bottom: "5%",
                top: "10%",
                containLabel: true,
            },
            xAxis: {
                min: 0,
                max: 100,
                type: 'value',
                axisLabel: { formatter: "{value}%" },
                splitLine: { show: true },
            },
            yAxis: {
                type: "category",
                axisLabel: { show: true },
                splitLine: { show: false },
            },
            series: [
                {
                    type: "bar",
                    barWidth: 15,
                    encode: { x: "Percentage", y: "domain" },
                    itemStyle: {
                        borderRadius: [0, 8, 8, 0],
                        color: (params) => {
                            const colorList = [
                                "#3182CE",
                                "#48BB78",
                                "#F6AD55",
                                "#9F7AEA",
                                "#ECC94B",
                            ];
                            return colorList[params.dataIndex % colorList.length];
                        },
                    },
                    label: {
                        show: true,
                        position: "right",
                        formatter: (params) => `${params.value.Percentage}%`,
                    },
                },
            ],
        };
    };

    const selectedStudent = getUserByID(selectedStudentId);
    const selectedCourse = getCourseById(selectedCourseId);

    return (
        <div className="p-6 space-y-6">
            {/* Filters */}
            <div className="flex justify-between mb-4 space-x-4">
                {!isStudent && (
                    <Dropdown
                        label={selectedStudentId ? `Student: ${getUserByID(selectedStudentId).firstName} ${getUserByID(selectedStudentId).lastName}` : "Select Student"}
                        inline
                        className="p-5 text-base font-semibold text-gray-900 dark:text-white"
                    >
                        {/* <Searchbar
                            data={searchTermStudents}
                            onFilter={setSearchTermStudents}
                            searchLabel={"Search students..."}
                        /> */}
                        <div className="max-h-[400px] overflow-auto">
                            {filteredStudents.map((student) => (
                                <Dropdown.Item
                                    key={student.id}
                                    onClick={() => setSelectedStudentId(selectedStudentId === student.id ? null : student.id)}
                                    className="cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-700 flex items-center space-x-2"
                                >
                                    <Checkbox
                                        checked={selectedStudentId === student.id}
                                        onChange={() => setSelectedStudentId(selectedStudentId === student.id ? null : student.id)}
                                        className="mr-2"
                                    />
                                    <span>{student.firstName} {student.lastName}</span>
                                </Dropdown.Item>
                            ))}
                        </div>
                    </Dropdown>
                )}

                <Dropdown
                    label={selectedCourseId ? `Course: ${getCourseById(selectedCourseId).name}` : "Select Course"}
                    inline
                    className="p-5 text-base font-semibold text-gray-900 dark:text-white"
                >
                    {/* <Searchbar
                        data={searchTermCourses}
                        onFilter={setSearchTermCourses}
                        searchLabel={"Search courses..."}
                    /> */}
                    <div className="max-h-[400px] overflow-auto">
                        {filteredCourses.map((course) => (
                            <Dropdown.Item
                                key={course.id}
                                onClick={() => setSelectedCourseId(selectedCourseId === course.id ? null : course.id)}
                                className="cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-700 flex items-center space-x-2"
                            >
                                <Checkbox
                                    checked={selectedCourseId === course.id}
                                    onChange={() => setSelectedCourseId(selectedCourseId === course.id ? null : course.id)}
                                    className="mr-2"
                                />
                                <span>{course.name}</span>
                            </Dropdown.Item>
                        ))}
                    </div>
                </Dropdown>
            </div>

            {/* Competency Chart */}
            <div className="bg-white border rounded-lg p-6">
                <ReactECharts option={getCompetencyChartOptions()} style={{ height: "400px" }} />
            </div>

            {/* Accordion for Domains */}
            <Accordion>
                {Object.keys(AACNDomains).map((domain, index) => {

                    // const result = calculateAACNScore(grades, index).percentage;
                    const result = results[index]?.domainScore || 0;

                    return (
                        <Accordion.Panel key={index} open={index === 0}>
                            <Accordion.Title>Domain {index + 1}: {domain}</Accordion.Title>
                            <Accordion.Content>
                                <div className="p-4 mb-5 font-semibold flex items-center justify-between">
                                    <span>Overall Competency Score</span>
                                    <div className="w-12 h-12 mr-4">
                                        <CircularProgressbar
                                            value={result}
                                            text={`${result}%`}
                                            styles={buildStyles({
                                                textSize: "34px",
                                                pathColor: "#4f46e5", // Customize the progress bar color
                                                textColor: "#4f46e5",
                                            })}
                                        />
                                    </div>
                                </div>
                                <Accordion>
                                    {Object.keys(AACNCoreCompetencies[index]).map((sub, subIndex) => {
                                        return (
                                            <Accordion.Panel key={subIndex} open={false}>
                                                <Accordion.Title className="font-semibold">{index + 1}.{subIndex + 1} {sub}</Accordion.Title>
                                                <Accordion.Content>
                                                    <ul className="list-disc pl-5">
                                                        {Object.keys(AACNSubCompetencies[index]?.[subIndex] ?? []).map((item, itemIndex) => {
                                                            const itemResult = results[index]?.coreCompetencies[subIndex]?.subCompetencies[itemIndex] || 0;
                                                            return (
                                                                <div className="flex justify-between items-center">
                                                                    <li className="py-4 font-regular" key={itemIndex}>
                                                                        <span>{index + 1}.{subIndex + 1}{(itemIndex + 10).toString(36)} {item}</span>
                                                                    </li>
                                                                    <div style={{ width: "50px", height: "50px" }} className="flex items-center">
                                                                        <CircularProgressbar
                                                                            value={itemResult}
                                                                            text={`${itemResult}%`}
                                                                            styles={buildStyles({
                                                                                textSize: "30px", // Ensure text fits well in smaller size
                                                                                pathColor: itemResult <= 50 ? "#f87171" : itemResult <= 70 ? "#fbbf24" : "#4ade80", // Red for <=50, Yellow for <=70, Green for >70
                                                                                textColor: itemResult <= 50 ? "#f87171" : itemResult <= 70 ? "#fbbf24" : "#4ade80",
                                                                            })}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            );

                                                        })}
                                                    </ul>
                                                </Accordion.Content>
                                            </Accordion.Panel>);
                                    })}</Accordion>
                            </Accordion.Content>
                        </Accordion.Panel>
                    )
                })}

            </Accordion>
        </div>
    );
};

export default AANCCoreCompetencies;