import React, { useEffect, useState } from 'react'
import DataTable from '../components/shared/Datatable';
import defaultImage from '../assets/images/22.png';
import useUsers from '../hooks/useUsers';
import { DateTimeFormat } from 'intl';
import { HiDuplicate, HiMail, HiOutlineDuplicate, HiOutlineMail, HiPlus, HiUsers, HiX } from 'react-icons/hi';
import useSchool from '../hooks/useSchool';
import { render } from '@testing-library/react';
import Searchbar from '../components/shared/Searchbar';
import { usePopUp } from '../hooks/usePopUp';
import InviteUser from '../components/authentication/IniviteUser';
import UserInfo from '../components/users/UserInfo';
import { TabsUnderlined } from '../components/shared/TabNavigation';
import useRegistrations from '../hooks/useRegistrations';
import { NotificationTypes } from '../utils/notifications';
import { useNotification } from '../hooks/useNotification';
import { auth } from '../firebase/firebase';
import UserRow from '../components/shared/UserRow';
import useAuth from '../hooks/useAuth';
import GenericButton from '../components/shared/GenericButton';
import { fetchSignInMethodsForEmail } from 'firebase/auth';
import { HiOutlineArrowTrendingUp } from 'react-icons/hi2';
import { BatchInviteUsers } from '../components/authentication/BatchInviteUsers';

const Tabs = {
    USERS: "Users",
    REGISTRATIONS: "Registrations",
    GROUPS: "Groups"
}

const tabs = [Tabs.USERS, Tabs.REGISTRATIONS, Tabs.GROUPS];

const Users = () => {
    const { showPopUp } = usePopUp();
    const [tab, setTab] = useState(0);
    const { isAdmin } = useAuth();

    const handleAddUserClick = () => {
        showPopUp(<InviteUser />);
    };

    const onInviteMultiClick = () => {
        showPopUp(<BatchInviteUsers />);
    }

    const handleCreateGroup = () => {
        showPopUp(<CreateGroup />);
    };

    const handleAddMultipleUserClick = () => {
        // showPopUp(<MultiInviteUser onClose={() => showPopUp(null)} currentUser={user} />);
    };

    return (
        <div className="p-6 bg-gray-50 dark:bg-gray-900 min-h-screen w-full h-full">
            <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6 h-full flex flex-col">
                <div className="flex justify-between items-center space-x-2 p-8">
                    <div className="flex items-center">
                        <HiUsers className="text-4xl text-gray-700 dark:text-gray-300" />
                        <h1 className="text-xl px-2 font-semibold text-gray-900 dark:text-white">
                            {tabs[tab]}
                        </h1>
                    </div>
                    <div className="flex space-x-2 justify-end">
                        {
                            tab < 2
                                ? <button
                                    onClick={handleAddUserClick}
                                    className="flex items-center justify-center border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 text-gray-700 dark:text-white hover:bg-gray-100 dark:hover:bg-gray-600 px-4 py-2 rounded-lg shadow-sm font-medium"
                                >
                                    <HiPlus className="h-5 w-5 mr-2" />
                                    Invite User
                                </button>
                                : isAdmin && <GenericButton icon={<HiPlus />} label="Create group" handleClick={handleCreateGroup} />
                        }
                        {
                            tab < 2 &&
                            <button
                                onClick={onInviteMultiClick}
                                className="flex items-center justify-center border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 text-gray-700 dark:text-white hover:bg-gray-100 dark:hover:bg-gray-600 px-4 py-2 rounded-lg shadow-sm font-medium"
                            >
                                <HiPlus className="h-5 w-5 mr-2" />
                                Batch Invite Users
                            </button>
                        }
                        {/* <button
                            onClick={handleAddMultipleUserClick}
                            className="flex items-center justify-center border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 text-gray-700 dark:text-white hover:bg-gray-100 dark:hover:bg-gray-600 px-4 py-2 rounded-lg shadow-sm font-medium"
                        >
                            <HiPlus className="h-5 w-5 mr-2" />
                            Invite Multiple
                        </button> */}
                    </div>
                </div>

                <TabsUnderlined tabs={tabs} activeTab={tab} setActiveTab={setTab} />

                {tabs[tab] === Tabs.USERS && <UsersTable />}
                {tabs[tab] === Tabs.REGISTRATIONS && <RegistrationsTable />}
                {tabs[tab] === Tabs.GROUPS && <UserGroupsTable />}
            </div>
        </div>
    )
}

const UsersTable = () => {
    const { showPopUp } = usePopUp();
    const { users, loading, deleteUser } = useUsers();
    //Filtered users by searchbar, this is allowed in the page since this is only required as gateway between the search bar and the datatable
    const [filteredUsers, setFilteredUsers] = useState(users);

    const { isAdmin, isSuperAdmin } = useAuth();
    const { showNotification } = useNotification();

    const handleShowUserClick = (userId) => {
        showPopUp(<UserInfo userId={userId} onClose={() => showPopUp(null)} />);
    }

    const handleDeleteUser = (user) => {
        deleteUser(user.id);
    }

    const columns = [
        {
            key: "name",
            label: "NAME",
            render: (_, user) => {
                return (
                    <UserRow user={user} />);
            }
        },
        {
            key: "role",
            label: "ROLE",
            render: (_, user) => {
                return (
                    (
                        <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${user.role?.trim() === 'Teacher'
                            ? 'bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-300'
                            : user.role?.trim() === 'Student'
                                ? 'bg-blue-100 text-blue-800 dark:bg-blue-900 dark:text-blue-300'
                                : user.role?.trim() === 'Admin'
                                    ? 'bg-red-100 text-red-800 dark:bg-red-900 dark:text-red-300'
                                    : 'bg-gray-100 text-gray-800 dark:bg-gray-900 dark:text-gray-300'
                            }`}>
                            {user.role?.trim() || 'N/A'}
                        </span>
                    )
                );
            }
        },
        {
            key: "createdAt",
            label: "DATE JOINED",
            render: (date, user) => {
                const formattedDate = date.toDate().toLocaleString().split(",")[0];
                return (<div>{formattedDate}</div>)
            }
        }
    ];

    return (
        <div>
            {/* Searchbar */}
            <Searchbar
                data={users} // Pass the original users array
                searchLabel={"name"}
                filterKeys={["firstName", "lastName"]} // Define the key to filter by
                onFilter={setFilteredUsers} // Update the filtered data
            />

            {/* Data table */}
            <div className="flex-grow">
                <DataTable
                    rows={filteredUsers}
                    columns={columns}
                    isLoading={loading}
                    onViewClick={
                        // (e) => { console.log(e) }
                        handleShowUserClick
                    }
                    onDeleteClick={
                        isAdmin ? handleDeleteUser : null
                    }
                    customActions={isSuperAdmin() ? [
                        {
                            icon: <HiDuplicate />,
                            onClick: (user) => {
                                navigator.clipboard.writeText(user.id).then(() => {
                                    showNotification(NotificationTypes.INFO, "User ID copied to clipboard.")
                                })
                            }
                        }
                    ] : []}
                />
            </div>
        </div>
    );
}


const RegistrationsTable = () => {
    const { registrations, loading, deleteRegistration, resendRegistration } = useRegistrations();
    //Filtered users by searchbar, this is allowed in the page since this is only required as gateway between the search bar and the datatable
    const [filteredRegistrations, setFilteredRegistrations] = useState(registrations);

    const { school } = useSchool();

    const { showNotification } = useNotification();

    const columns = [
        {
            key: "name",
            label: "NAME",
            render: (_, user) => {
                return (
                    <div className="text-sm font-medium text-gray-900 dark:text-white">
                        {`${user.firstName} ${user.lastName}`}
                    </div>);
            }
        },
        {
            key: "email",
            label: "EMAIL",
        },
        {
            key: "role",
            label: "ROLE",
            render: (_, user) => {
                return (
                    (
                        <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${user.role?.trim() === 'Teacher'
                            ? 'bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-300'
                            : user.role?.trim() === 'Student'
                                ? 'bg-blue-100 text-blue-800 dark:bg-blue-900 dark:text-blue-300'
                                : user.role?.trim() === 'Admin'
                                    ? 'bg-red-100 text-red-800 dark:bg-red-900 dark:text-red-300'
                                    : 'bg-gray-100 text-gray-800 dark:bg-gray-900 dark:text-gray-300'
                            }`}>
                            {user.role?.trim() || 'N/A'}
                        </span>
                    )
                );
            }
        },
        {
            key: "createdAt",
            label: "DATE INVITED",
            render: (date, user) => {
                const formattedDate = date.toDate().toLocaleString().split(",")[0];
                return (<div>{formattedDate}</div>)
            }
        }
    ];


    return (
        <div>
            {/* Searchbar */}
            <Searchbar
                data={registrations} // Pass the original users array
                searchLabel={"name"}
                filterKeys={["firstName", "lastName"]} // Define the key to filter by
                onFilter={setFilteredRegistrations} // Update the filtered data
            />

            {/* Data table */}
            <div className="flex-grow">
                <DataTable
                    rows={filteredRegistrations}
                    columns={columns}
                    isLoading={loading}
                    onDeleteClick={(registration) => {
                        deleteRegistration(registration.id);
                    }}
                    customActions={[{
                        icon: <HiOutlineMail title='Resend invite link' />, onClick: (registration) => {
                            resendRegistration(registration.id);
                        }
                    },
                    {
                        icon: <HiOutlineDuplicate title='Copy invite link' />, onClick: async (registration) => {
                            const signupLink = `https://coursetavr.com/#/signup?token=${registration.id}&institution=${school.id}`;
                            await navigator.clipboard.writeText(signupLink);
                            showNotification(NotificationTypes.SUCCESS, "Invite link copied!");
                        }
                    }
                    ]
                    }
                />
            </div>
        </div>
    );
}

const UserGroupsTable = () => {
    const { showPopUp } = usePopUp();
    const { users, loading, groups, deleteGroup, getUserGroupByID } = useUsers();
    //Filtered users by searchbar, this is allowed in the page since this is only required as gateway between the search bar and the datatable
    const [filteredGroups, setFilteredGroups] = useState(users);

    const { isAdmin } = useAuth();
    const { showNotification } = useNotification();

    const handleShow = (groupId) => {
        const group = getUserGroupByID(groupId);
        showPopUp(<CreateGroup group={group} />);
    }

    const handleDuplicate = (group) => {
        showPopUp(<CreateGroup group={group} duplicate={true} />);
    }

    const handleEdit = (groupId) => {
        const group = getUserGroupByID(groupId);
        showPopUp(<CreateGroup group={group} edit={true} />);
    }

    const handleDeleteGroup = (group) => {
        deleteGroup(group.id);
    }

    const columns = [
        {
            key: "name",
            label: "NAME",
            render: (_, group) => {
                return (
                    <div>{group.name}</div>);
            }
        },
        {
            key: "users",
            label: "NR. OF USERS",
            render: (_, group) => {
                return (
                    <div>{group.users?.length}</div>
                );
            }
        },
        {
            key: "createdAt",
            label: "CREATED AT",
            render: (date, _) => {
                if (!date) return null;
                const formattedDate = date.toDate().toLocaleString().split(",")[0];
                return (<div>{formattedDate}</div>)
            }
        }
    ];

    return (
        <div>
            {/* Searchbar */}
            <Searchbar
                data={groups} // Pass the original users array
                searchLabel={"name"}
                filterKeys={["name"]} // Define the key to filter by
                onFilter={setFilteredGroups} // Update the filtered data
            />

            {/* Data table */}
            <div className="flex-grow">
                <DataTable
                    rows={filteredGroups}
                    columns={columns}
                    isLoading={loading}
                    onViewClick={
                        // (e) => { console.log(e) }
                        handleShow
                    }
                    onEditClick={
                        isAdmin ? handleEdit : null
                    }
                    onDeleteClick={
                        isAdmin ? handleDeleteGroup : null
                    }
                    customActions={isAdmin ? [
                        {
                            icon: <HiDuplicate />,
                            onClick: handleDuplicate
                        }
                    ] : []}
                />
            </div>
        </div>
    );
}

export const CreateGroup = ({ group = null, edit = false, duplicate = false }) => {
    const { showPopUp } = usePopUp();
    const [name, setName] = useState(edit ? group.name : "");
    const [groupUsers, setGroupUsers] = useState(group ? group.users : []);
    const { users, createGroup, getUserByID, updateGroup } = useUsers();

    const [searchTerm, setSearchTerm] = useState("");

    // Filter users based on the search term
    const filteredUsers = users.filter((user) =>
        user.role === "Student" && `${user.firstName} ${user.lastName}`.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const onClose = () => {
        showPopUp(null);
    }

    const handleSubmit = async () => {
        onClose();
        if (edit) {
            await updateGroup(group.id, { name: name, users: groupUsers });
        }
        else {
            await createGroup(name, groupUsers);
        }
    }

    const handleUserSelection = async (userId) => {
        setGroupUsers((prev) =>
            prev.includes(userId) ? prev.filter(uid => uid !== userId) : [...prev, userId]
        )
    }

    const getAction = () => { return group ? edit ? "Edit" : duplicate ? "Duplicate" : "View" : "Create"; }

    const canEdit = !group || duplicate || edit;

    return (
        <div className={`fixed inset-0 z-50 flex items-center justify-center`}>
            <div className="relative w-full max-w-2xl bg-white rounded-lg shadow dark:bg-gray-800 overflow-hidden max-h-screen p-4">
                <div className="overflow-auto max-h-[80vh]">
                    <div className="flex justify-between items-center pb-4 mb-4 border-b dark:border-gray-600">
                        <h3 className="text-lg font-medium text-gray-900 dark:text-gray-300 mb-2">{`${getAction()} Group`}</h3>
                        <button
                            onClick={onClose}
                            type="button"
                            className="text-red-600 bg-transparent hover:bg-red-100 hover:text-red-800 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center justify-center dark:hover:bg-red-800 dark:hover:text-red-400"
                            style={{ marginTop: '8px', marginRight: '8px' }}  // Extra marge toegevoegd
                        >
                            <span className="sr-only">Close modal</span>
                            <HiX />
                        </button>
                    </div>

                    {canEdit ? <form onSubmit={handleSubmit}>
                        <div className="mb-6">
                            <label className="block mb-2 text-sm text-gray-900 dark:text-gray-300">Group name</label>
                            <input
                                type="text"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-3"
                                placeholder="Enter group name"
                                required
                            />
                        </div>
                        {/* When duplicating you can not edit the users list */}
                        {!duplicate && <fieldset className="mb-4">
                            <legend className="block mb-2 text-sm text-gray-900 dark:text-gray-300">Users</legend>
                            {/* Search box */}
                            <input
                                type="text"
                                placeholder="Search users..."
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                                className="mb-2 p-2 w-full text-sm border border-gray-300 rounded dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                            />
                            {/* Scrollable list */}
                            <div className="max-h-64 overflow-y-auto">
                                {filteredUsers.length > 0 ? (
                                    filteredUsers.map((user) => (
                                        <div key={user.id} className="user-item flex items-center py-1">
                                            <input
                                                type="checkbox"
                                                id={`user-${user.id}`}
                                                name="user"
                                                value={user.id}
                                                checked={groupUsers.includes(user.id)}
                                                onChange={() => handleUserSelection(user.id)}
                                                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                            />
                                            <label
                                                htmlFor={`user-${user.id}`}
                                                className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                            >
                                                {user.firstName} {user.lastName}
                                            </label>
                                        </div>
                                    ))
                                ) : (
                                    <p className="text-sm text-gray-500 dark:text-gray-400">No users found.</p>
                                )}
                            </div>
                        </fieldset>}
                        <button type="submit" className="w-full justify-center bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700 focus:ring focus:ring-blue-300">
                            {getAction()} Group
                        </button>
                    </form> :
                        <div>
                            <div className="mb-4">
                                <label className="block mb-2 text-sm text-gray-900 dark:text-gray-300">Group name</label>
                                <p className="text-sm font-medium text-gray-900 dark:text-gray-300">
                                    {group.name}
                                </p>
                            </div>
                            <fieldset >
                                <legend className="block mb-2 text-sm text-gray-900 dark:text-gray-300">Group Members</legend>
                                <div className="max-h-64 overflow-y-auto">
                                    {group.users.length > 0 ? (
                                        group.users.map((userId) => {
                                            const user = getUserByID(userId);
                                            return (
                                                <div key={user.id} className="user-item py-1">
                                                    <p className="text-sm font-medium text-gray-900 dark:text-gray-300">
                                                        {user.firstName} {user.lastName}
                                                    </p>
                                                </div>
                                            )
                                        })
                                    ) : (
                                        <p className="text-sm text-gray-500 dark:text-gray-400">No users in the group.</p>
                                    )}
                                </div>
                            </fieldset>
                        </div>}
                </div>
            </div>
        </div>
    );
}

export default Users;