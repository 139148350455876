import React, { useEffect, useState } from 'react';
import { TabsBubble } from "../shared/TabNavigation"
import useScenarios from "../../hooks/useScenarios";
import RubricTab from "./scenarios/RubricTab";
import CurriculumTab from "./scenarios/CurriculumTab";
import SimulationTab from "./scenarios/SimulationTab";
import EMRTab from "./scenarios/EMRTab";
import OverviewTab from "./scenarios/OverviewTab";
import SaveButton from "../shared/SaveButton";
import GenericButton from "../shared/GenericButton";
import { HiAdjustments, HiPencil, HiSave } from 'react-icons/hi';
import CharacterDetails from './CharacterDetails';
import Roles from '../../utils/roles';
import useAuth from '../../hooks/useAuth';
import ReturnButton from '../shared/ReturnButton';
import useScenarioCustomization from '../../hooks/useScenarioCustomization';
import { ScriptingProvider } from '../../contexts/ScriptingContext';
import VisualScripting from '../scripting/VisualScripting';
import { useSearchParams } from 'react-router-dom';
import { usePopUp } from '../../hooks/usePopUp';
import ConfirmPopup from '../shared/ConfirmPopup';
import { HiExclamationTriangle } from 'react-icons/hi2';

const Tabs = {
    CURRICULUM: "Curriculum",
    SIMULATION: "Simulation",
    EMR: "EMR",
    RUBRIC: "Rubric",
    OVERVIEW: "Overview",
    SCRIPTING: "Scripting"
}

const tabs = [Tabs.CURRICULUM, Tabs.SIMULATION, Tabs.EMR, Tabs.RUBRIC, Tabs.SCRIPTING, Tabs.OVERVIEW];

const ScenarioDetails = () => {
    const { scenario, save, isDirty, saving, patient } = useScenarioCustomization();
    const [activeTab, setActiveTab] = useState(0);
    const { isAdmin } = useAuth();

    const { showPopUp } = usePopUp();

    const filteredTabs = tabs.filter((tab) => {
        // if (tab === Tabs.SCRIPTING && !isAdmin) {
        //     return false;
        // }
        return true;
    });

    const handleTabChange = (index) => {
        setActiveTab(index);
    };

    const renderActiveComponent = () => {
        switch (filteredTabs[activeTab]) {
            case Tabs.CURRICULUM:
                return <CurriculumTab />;
            case Tabs.SIMULATION:
                return <SimulationTab />;
            case Tabs.EMR:
                return <EMRTab />;
            case Tabs.RUBRIC:
                return <RubricTab />;
            case Tabs.SCRIPTING:
                return <Scripting />;
            case Tabs.OVERVIEW:
                return <OverviewTab scenario={scenario} patient={patient} />;
            // return isAdmin ?
            //     <div className="bg-white rounded-md">
            //         <h2 className="pt-6 px-6 text-2xl font-semibold mb-4">Scripting</h2>
            //         <p className="px-6 mb-4 text-gray-600">Script events and effects in your simulation (Optional)</p>
            //         <ScriptingProvider><VisualScripting /></ScriptingProvider>
            //     </div> : null;
            default:
                return <div>Select a tab to view content.</div>;
        }
    };

    if (!scenario) {
        return <div>Loading...</div>
    }

    return (
        <div className="w-full bg-gray-100 dark:bg-gray-900 min-h-screen p-4">
            <div className="bg-white dark:bg-gray-800 rounded-lg shadow-md">
            <h3 className='font-semibold text-l pt-10 justify-center flex'>{scenario.name}</h3>
                <div className="p-10 flex items-center justify-between pt-2">
                    <div className="flex w-1/5">
                        <ReturnButton customLabel="Exit" isDirty={isDirty} />
                    </div>
                    <div className="flex items-center w-3/5">
                        <TabsBubble
                            tabs={filteredTabs}
                            activeTab={activeTab}
                            setActiveTab={handleTabChange}
                        />
                    </div>
                    <div className="flex w-1/5 justify-end">
                        <SaveButton hasUnsavedChanges={isDirty} saveChanges={save} saving={saving} />
                    </div>
                </div>

                {/* Render the active component based on the active tab */}
                <div className="">{renderActiveComponent()}</div>
            </div>
        </div>
    );
};

const Scripting = () => {
    const { showPopUp } = usePopUp();

    useEffect(() => {
        // Check if the popup has already been shown
        const hasSeenPopup = localStorage.getItem('hasSeenAdvancedScriptingPopup');
        console.log(hasSeenPopup);
        if (!hasSeenPopup || hasSeenPopup === 'false') {
            showPopUp(<ScriptingWarning />);
        }
    }, []);

    return (
        <div className="bg-white rounded-md">
            <h2 className="pt-6 px-6 text-2xl font-semibold mb-4">Scripting</h2>
            <p className="px-6 mb-4 text-gray-600">Script events and effects in your simulation (Optional)</p>
            <ScriptingProvider><VisualScripting /></ScriptingProvider>
        </div>);
}

const ScriptingWarning = () => {
    const { showPopUp } = usePopUp();

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white p-6 rounded-md shadow-lg max-w-md w-full">
                <h2 className="text-lg font-bold mb-4">Advanced Scripting Tool</h2>
                <p className="mb-4">
                    This optional scripting tool is for advanced events and effects to occur in your simulation.
                    By selecting conditions, you have pre-scripted all dynamic responses to your students' actions.
                    Place any specific events to occur in your simulation outside of patient pathophysiology.
                    Script events such as distractors, unique patient interactions, and behaviors.
                </p>
                <button
                    onClick={(e) => {
                        e.preventDefault();
                        localStorage.setItem('hasSeenAdvancedScriptingPopup', 'true'); // Mark as shown
                        showPopUp(null);
                    }}
                    className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
                >
                    Got it
                </button>
            </div>
        </div>
    );
};

export default ScenarioDetails;