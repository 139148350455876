import React from "react";
import { HiX } from "react-icons/hi";

const PopupContainer  = ({ children, onClose, title = "Scenario Overview" }) => {
    return (
      <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center z-50 p-8">
        <div className="relative bg-white rounded-lg shadow-lg w-full max-w-8xl h-[80vh] flex flex-col">
          {/* Header */}
          <div className="bg-black text-white p-4 flex justify-between items-center rounded-t-lg">
            <h2 className="text-xl font-semibold">{title}</h2>
            <button onClick={onClose} className="text-white hover:text-gray-300">
              <HiX className="h-6 w-6" />
            </button>
          </div>
          {/* Main Content */}
          <div className="flex-1 overflow-y-auto p-6">
            {children}
          </div>
        </div>
      </div>
    );
  };
  
  export default PopupContainer;