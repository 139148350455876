//const xlsx = require('xlsx') as typeof import('xlsx');
import * as xlsx from 'xlsx'

// =================================================================================================================================== XLSX

/**
 * Reads and validates worksheets in a `.xlsx` file, returning worksheet datas where all specified `requiredColumns` are present 
 * and potential error messages for "invalid" worksheets (missing one or more required columns).
 * 
 * @param file File reference to a `.xlsx` file
 * @param requiredColumns Columns that are required to be present in a worksheet for it to be considered valid
 * @returns `[{ data: { columMap: Map<columnName, columnIndex>, data: rows }, err: error message }]` An array of promises of a result for `file`,
 *   where the result is the data if the header contains all required columns (otherwise undefined).
 */
export async function xlsxReadFileWithRequiredColumns(
    file,
    requiredColumns
) {
    return new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsArrayBuffer(file);
        reader.onload = (e) => {
            if (e.target !== null) {
                const dataBuf = new Uint8Array(e.target.result);
                const workbook = xlsx.read(dataBuf, { type: 'array' });

                if (!workbook) {
                    return resolve([{ data: undefined, err: `Could not find a workbook.` }]);
                }

                if (workbook.SheetNames.length === 0) {
                    return resolve([{ data: undefined, err: `Workbook does not contain any sheets.` }]);
                }

                let results = [];

                for (const name of workbook.SheetNames) {
                    // There's easier ways to do this but getting `header` (see below) this way is easier for other formats
                    const data = xlsx.utils.sheet_to_json(workbook.Sheets[name]);
                    const header = Object.keys(data[0]);

                    const requiredColumnMapResult = getRequiredColumnMap(header, requiredColumns, name);
                    if (requiredColumnMapResult.err !== undefined) {
                        results.push({ data: undefined, err: requiredColumnMapResult.err });
                        continue;
                    }

                    // Get required columns
                    const requiredColumnMap = requiredColumnMapResult.map;
                    const resultData = {
                        columnMap: requiredColumnMap,
                        data: [],
                    };
                    data.forEach((row) => {
                        let newRow = [];
                        Object.values(row).forEach((col) => {
                            let value;
                            if (typeof col === 'string') {
                                value = col.trim();
                            } else {
                                value = col;
                            }
                            newRow.push(value);
                        });
                        resultData.data.push(newRow);
                    });
                    results.push({ data: resultData, err: undefined });
                }

                resolve(results);
            } else {
                return resolve([{ data: undefined, err: `Error trying to load file."` }]);
            }
        };
    });
}

// ==================================================================================================================================== CSV

/**
 * Reads and validates the contents of a `.csv` file, returning data where all specified `requiredColumns` are present 
 * or a potential error message if the file is "invalid" (missing one or more required columns).
 * 
 * @param file File reference to a `.csv` file
 * @param requiredColumns Columns that are required to be present in the first row (header) of the file to be considered valid
 * @returns `{ data: { columMap: Map<columnName, columnIndex>, data: rows }, err: error message }` A promise of a result for `file`,
 *   where the result is the data if the header contains all required columns (otherwise undefined).
 */
export async function csvReadFileWithRequiredColumns(
    file,
    requiredColumns
) {
    return new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsText(file);
        reader.onload = (e) => {
            if (e.target !== null) {
                // Split file into entries
                const s = e.target.result;
                const rowsAsString = s.split(/\r?\n/);
                const rowsUnsanitized = rowsAsString.map(rowString => rowString.split(','));
                const rows = rowsUnsanitized.map(row => row.map(s => s.trim()));

                // Get required columns
                let data = undefined;
                let err = undefined;
                const requiredColumnMapResult = getRequiredColumnMap(rows[0], requiredColumns, 'CSV');
                if (requiredColumnMapResult.err !== undefined) {
                    err = requiredColumnMapResult.err;
                } else {
                    const requiredColumnMap = requiredColumnMapResult.map;
                    const resultData = {
                        columnMap: requiredColumnMap,
                        data: rows.slice(1),
                    };
                    data = resultData;
                }
                resolve({ data, err });
            } else {
                resolve({ data: undefined, err: `Error trying to load file."` });
            }
        };
    });
}

// =============================================================================================================================== UTIL FNS

/**
 * @param data Data extracted from a spreadsheet.
 * @param requiredCols Required columns for the spreadsheet (if any do not exist in the first row of `data`, an error is returned).
 * @param sheetName Name of the spreadsheet (worksheet for xlsx) `data` is from (used for potential error messages).
 * @returns A result depending on if all required columns exist in the specified data of a spreadsheet. The value for Ok represents
 * a dictionary where each required column `string` (as `Map`'s key `K`) has a matching column index (as `Map`'s value `V`).
 */
function getRequiredColumnMap(
    header,
    requiredCols,
    sheetName,
) {
    let err = undefined;
    let map = new Map();

    requiredCols.some((col) => {
        const index = header.findIndex(headerCol => headerCol === col);
        if (index < 0) {
            err = `Missing required column "${col}" in worksheet "${sheetName}"`;
        }
        map.set(col, index);
    });

    return { map, err }
}