import React, { createContext, useState, useEffect, useCallback } from "react";
import {
  addDocument,
  updateDocument,
  subscribeCollection,
  getDocuments,
} from "../firebase/firestore";
import { useNotification } from "../hooks/useNotification";
import { NotificationTypes } from "../utils/notifications";

const ResourcesContext = createContext();

// A valid empty DraftJS raw state
const defaultRawContent = {
  blocks: [
    {
      key: "init",
      text: "",
      type: "unstyled",
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {},
    },
  ],
  entityMap: {},
};

const ResourcesProvider = ({ children }) => {
  const [resources, setResources] = useState([]);
  const [subcategories, setSubcategories] = useState({});
  const [loading, setLoading] = useState(true);
  const { showNotification } = useNotification();

  // Subscribe to all categories
  useEffect(() => {
    const unsubscribe = subscribeCollection("resources", (data) => {
      console.log("Loaded categories:", data);
      setResources(data);
      setLoading(false);
    });
    return () => unsubscribe();
  }, []);

  // Fetch subcategories for a given category (if not already loaded)
  const fetchSubcategories = useCallback(
    async (categoryId) => {
      if (!categoryId || subcategories[categoryId]) return;
      try {
        const subcategoryData = await getDocuments(
          `resources/${categoryId}/subcategories`
        );
        console.log(`Fetched subcategories for ${categoryId}:`, subcategoryData);
        setSubcategories((prev) => ({
          ...prev,
          [categoryId]: subcategoryData,
        }));
      } catch (error) {
        console.error("Error fetching subcategories:", error);
      }
    },
    [subcategories]
  );

  // Add a new category (initialize with empty content and an empty "completed" array)
  const addCategory = async () => {
    try {
      const categoryName = prompt("Enter category name:");
      if (!categoryName) return;

      const newCategory = {
        name: categoryName,
        published: false,
        content: defaultRawContent,
        completed: [],
      };
      const categoryId = await addDocument(newCategory, "resources");

      setResources((prev) => [...prev, { id: categoryId, ...newCategory }]);
      showNotification(
        NotificationTypes.SUCCESS,
        `Category '${categoryName}' added.`
      );
    } catch (error) {
      console.error("Error adding category:", error);
      showNotification(NotificationTypes.DANGER, "Failed to add category.");
    }
  };

  // Add a subcategory (initialize with empty content and an empty "completed" array)
  const addSubcategory = async (categoryId) => {
    try {
      const subcategoryName = prompt("Enter subcategory name:");
      if (!subcategoryName) return;

      const newSubcategory = {
        name: subcategoryName,
        published: false,
        content: defaultRawContent,
        completed: [],
      };
      const subcategoryId = await addDocument(
        newSubcategory,
        `resources/${categoryId}/subcategories`
      );

      setSubcategories((prev) => ({
        ...prev,
        [categoryId]: [
          ...(prev[categoryId] || []),
          { id: subcategoryId, ...newSubcategory },
        ],
      }));

      showNotification(
        NotificationTypes.SUCCESS,
        `Subcategory '${subcategoryName}' added.`
      );
    } catch (error) {
      console.error("Error adding subcategory:", error);
      showNotification(NotificationTypes.DANGER, "Failed to add subcategory.");
    }
  };

  // Update category content or merged fields
  const updateResource = async (categoryId, data) => {
    try {
      await updateDocument(categoryId, "resources", data);
      setResources((prev) =>
        prev.map((cat) => (cat.id === categoryId ? { ...cat, ...data } : cat))
      );
      showNotification(NotificationTypes.SUCCESS, "Category updated.");
    } catch (error) {
      console.error("Error updating category:", error);
      showNotification(NotificationTypes.DANGER, "An error occurred.");
    }
  };

  // Update subcategory content or merged fields
  const updateSubcategory = async (categoryId, subcategoryId, data) => {
    try {
      await updateDocument(
        subcategoryId,
        `resources/${categoryId}/subcategories`,
        data
      );
      setSubcategories((prev) => ({
        ...prev,
        [categoryId]: prev[categoryId].map((subcat) =>
          subcat.id === subcategoryId ? { ...subcat, ...data } : subcat
        ),
      }));
      showNotification(NotificationTypes.SUCCESS, "Subcategory updated.");
    } catch (error) {
      console.error("Error updating subcategory:", error);
      showNotification(NotificationTypes.DANGER, "Failed to update subcategory.");
    }
  };

  // Official publish/unpublish function for categories (updates only "published")
  const publishCategory = async (categoryId, isPublished) => {
    try {
      await updateDocument(categoryId, "resources", { published: isPublished });
      setResources((prev) =>
        prev.map((cat) =>
          cat.id === categoryId ? { ...cat, published: isPublished } : cat
        )
      );
      showNotification(
        NotificationTypes.SUCCESS,
        `Category ${isPublished ? "published" : "unpublished"}.`
      );
    } catch (error) {
      console.error("Error publishing category:", error);
      showNotification(NotificationTypes.DANGER, "Failed to publish category.");
    }
  };

  // Official publish/unpublish function for subcategories (updates only "published")
  const publishSubcategory = async (categoryId, subcategoryId, isPublished) => {
    try {
      await updateDocument(
        subcategoryId,
        `resources/${categoryId}/subcategories`,
        { published: isPublished }
      );
      setSubcategories((prev) => ({
        ...prev,
        [categoryId]: prev[categoryId].map((subcat) =>
          subcat.id === subcategoryId ? { ...subcat, published: isPublished } : subcat
        ),
      }));
      showNotification(
        NotificationTypes.SUCCESS,
        `Subcategory ${isPublished ? "published" : "unpublished"}.`
      );
    } catch (error) {
      console.error("Error publishing subcategory:", error);
      showNotification(NotificationTypes.DANGER, "Failed to publish subcategory.");
    }
  };

  // Official function to toggle completion for a category.
  // It adds or removes the current user's id from the "completed" array.
  const toggleCompletionCategory = async (categoryId, userId) => {
    try {
      const category = resources.find((cat) => cat.id === categoryId);
      if (!category) return;
      let completed = category.completed || [];
      let newCompleted = completed.includes(userId)
        ? completed.filter((id) => id !== userId)
        : [...completed, userId];

      await updateDocument(categoryId, "resources", { completed: newCompleted });
      setResources((prev) =>
        prev.map((cat) =>
          cat.id === categoryId ? { ...cat, completed: newCompleted } : cat
        )
      );
      showNotification(
        NotificationTypes.SUCCESS,
        "Completion status updated."
      );
    } catch (error) {
      console.error("Error updating completion:", error);
      showNotification(
        NotificationTypes.DANGER,
        "Failed to update completion."
      );
    }
  };

  // Official function to toggle completion for a subcategory.
  const toggleCompletionSubcategory = async (categoryId, subcategoryId, userId) => {
    try {
      const subs = subcategories[categoryId] || [];
      const subcategory = subs.find((sub) => sub.id === subcategoryId);
      if (!subcategory) return;
      let completed = subcategory.completed || [];
      let newCompleted = completed.includes(userId)
        ? completed.filter((id) => id !== userId)
        : [...completed, userId];

      await updateDocument(
        subcategoryId,
        `resources/${categoryId}/subcategories`,
        { completed: newCompleted }
      );
      setSubcategories((prev) => ({
        ...prev,
        [categoryId]: prev[categoryId].map((sub) =>
          sub.id === subcategoryId ? { ...sub, completed: newCompleted } : sub
        ),
      }));
      showNotification(
        NotificationTypes.SUCCESS,
        "Completion status updated."
      );
    } catch (error) {
      console.error("Error updating subcategory completion:", error);
      showNotification(
        NotificationTypes.DANGER,
        "Failed to update completion."
      );
    }
  };

  return (
    <ResourcesContext.Provider
      value={{
        resources,
        subcategories,
        loading,
        addCategory,
        addSubcategory,
        updateResource,
        updateSubcategory,
        fetchSubcategories,
        publishCategory,
        publishSubcategory,
        toggleCompletionCategory,
        toggleCompletionSubcategory,
      }}
    >
      {children}
    </ResourcesContext.Provider>
  );
};

export { ResourcesProvider, ResourcesContext };
