import { roleToFBGucci, stringToRole, } from './roles';
import Roles from './roles';

import { csvReadFileWithRequiredColumns, xlsxReadFileWithRequiredColumns } from './spreadsheet'

const userInviteCols = ['firstName', 'lastName', 'email', 'role', 'courseNames'];

/**
 * Validates a spreadsheet file and transforms the file contents into results.
 * @param file Spreadsheet file to laod (.xlsx or .csv)
 * @param currentUserRole The role of the current user of the LMS (the "caller")
 * @param courses The courses the (currently selected) school offers
 * @returns An array of Results where each entry is either a valid `UserInvite` or an error.
 */
export async function validateUserInviteSpreadsheet(
    file,
    currentUserRole,
    courses,
    currentSchoolUsers,
) {
    const nameLower = file.name.toLowerCase();
    if (!nameLower.endsWith(".xlsx") && !nameLower.endsWith(".csv")) {
        return [{
            data: undefined, err: "Chosen file is not an accepted spreadsheet format(expected.xlsx or.csv)"
        }];
    }
    let worksheetResults = nameLower.endsWith('xlsx')
        ? await xlsxReadFileWithRequiredColumns(file, userInviteCols)
        : [await csvReadFileWithRequiredColumns(file, userInviteCols)];
    let inviteResults = [];
    worksheetResults.forEach((result) => {
        if (result.err !== undefined) {
            inviteResults.push({ data: undefined, err: result.err });
        } else {
            const sheet = result.data;
            // Theses should all never be undefined at this point; the ?? 0 is just to cement the type as 'number'
            const firstNameId = sheet.columnMap.get('firstName') ?? 0;
            const lastNameId = sheet.columnMap.get('lastName') ?? 0;
            const emailId = sheet.columnMap.get('email') ?? 0;
            const roleId = sheet.columnMap.get('role') ?? 0;
            const courseNamesId = sheet.columnMap.get('courseNames') ?? 0;
            const duplicateEmail = hasDuplicateEmails(sheet);

            if (duplicateEmail != null) {
                inviteResults.push({ data: undefined, err: `Duplicate email found: ${duplicateEmail}` });
            }

            // Sanitize and map the results
            sheet.data.forEach((row, rowId) => {
                let isValid = true;
                const firstName = row[firstNameId];
                const lastName = row[lastNameId];
                const email = (row[emailId]).toLowerCase();
                if (!isValidEmail(email, currentSchoolUsers)) {
                    inviteResults.push({ data: undefined, err: `Invalid email address in row ${rowId + 2}: ${row[emailId]}` });
                    isValid = false;
                }
                const role = row[roleId];
                const sanitizedRole = role?.toLowerCase().replace(/\s+/g, "_");
                if (!isValidRole(sanitizedRole)) {
                    inviteResults.push({ data: undefined, err: `Invalid role in row ${rowId + 2}: ${role}` });
                    isValid = false;
                } else if (!authorizedToInviteRole(sanitizedRole, currentUserRole)) {
                    inviteResults.push({ data: undefined, err: `You are not authorized to invite users for role '${role}' in row ${rowId + 2}` });
                    isValid = false;
                }
                const courseNamesString = row[courseNamesId];
                const courseNamesUnsanitized = courseNamesString.split(';');
                const courseNames = courseNamesUnsanitized.map(s => s.trim());
                courseNames.forEach(courseName => {
                    if (!isValidCourseName(courseName, courses)) {
                        if (courseName.length == 0) {
                            inviteResults.push({ data: undefined, err: `Invalid course name (empty) in row ${rowId + 2} ${courseNamesString}` });
                        } else {
                            inviteResults.push({ data: undefined, err: `Unknown course name '${courseName}' in row ${rowId + 2}: ${courseNamesString}` });
                        }
                        isValid = false;
                    }
                });
                if (!isValid) return;
                const gucciRole = roleToFBGucci(sanitizedRole);
                const value = {
                    firstName,
                    lastName,
                    email,
                    role: gucciRole,
                    courseNames,
                };
                inviteResults.push({ data: value, err: undefined });
            });
        }
    });
    return inviteResults;
}

function isValidEmail(email, currentSchoolUsers) {
    let isValid = true;

    // Regex (syntax)
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) isValid = false;

    return isValid;
}

// Only checks if it is a known role; authorization is checked using authorizedToInviteRole()
function isValidRole(role) {
    // TODO MAANDAG: In IniteUsers.js, roles are capitalized, in  they're not... WHICH ONE IS IT?!
    return stringToRole(role) !== null
}

function hasDuplicateEmails(sheet) {
    const emailColumnIndex = sheet.columnMap.get("email") ?? 0;
    const emails = sheet.data.map(row => row[emailColumnIndex]);

    let duplicateEmail = null;
    const seen = new Set();
    if (emails.some(email => {
        if (seen.has(email)) {
            duplicateEmail = email;
            return true; // Duplicate found
        }
        seen.add(email);
        return false;
    })) {
        return duplicateEmail;
    }
    return null;
}

function authorizedToInviteRole(roleToInvite, currentUserRole) {
    let authorized = false;
    const sanitizedRole = roleToInvite.toLowerCase().replace(/\s+/g, "_");

    /*
        NOTE(Rens): writing it out like this may seem too verbose, but !== isn't future proof;
        having authorization errors for new roles is preferable over accidental invites of unauthorized roles
    */

    // Admins (super or not) may invite school admins, teachers and students
    if (currentUserRole === Roles.ADMIN) {
        if (sanitizedRole === Roles.SCHOOLADMIN
            || sanitizedRole === Roles.TEACHER
            || sanitizedRole === Roles.STUDENT) {
            authorized = true;
        }
        // School admins and teachers may invite teachers and students
    } else if (currentUserRole === Roles.SCHOOLADMIN || currentUserRole === Roles.TEACHER) {
        if (sanitizedRole === Roles.TEACHER
            || sanitizedRole === Roles.STUDENT) {
            authorized = true;
        }
    }
    return authorized;
}

// TODO(Rens): Refactor courses to be typed (enum)
function isValidCourseName(name, courses) {
    let foundCourse = false;
    courses.forEach((course) => {
        if (course.name === name) {
            foundCourse = true;
            return
        }
    });
    return foundCourse;
}