import React, { createContext, useState, useEffect } from 'react';
import { addDocument, deleteDocument, subscribeCollection, updateDocument } from '../firebase/firestore';
import useSchool from '../hooks/useSchool';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { NotificationTypes } from '../utils/notifications';
import { useNotification } from '../hooks/useNotification';
import { serverTimestamp } from 'firebase/firestore';
import useAuth from '../hooks/useAuth';

const UsersContext = createContext();

/**
 * The context for all authentication related data such as the signed in user data, authentication loading or authentication error messages
 * @param {*} children The provider's children
 * @returns 
 */
const UsersProvider = ({ children }) => {
    const { school } = useSchool();
    const [users, setUsers] = useState([]);
    const [groups, setGroups] = useState([]);
    const [groupsMap, setGroupsMap] = useState(new Map());
    const [admins, setAdmins] = useState([]);
    const [usersMap, setUsersMap] = useState(new Map());
    const [loading, setLoading] = useState(true);
    const { user } = useAuth();

    const { showNotification } = useNotification();

    //The school context listens to changes to school ID and loads the school data for the newly set school ID on changes
    useEffect(() => {
        if (school) {
            const unsubscribeUsers = subscribeCollection(`institutions/${school.id}/users`, (data) => {
                console.log("Refreshed school users: ", data)
                setUsers(data);
                setLoading(false);
                if (data)
                    setUsersMap(new Map(data.map((item) => [item.id, item])));
                else
                    setUsersMap(new Map());
            });

            const unsubscribeAdmins = subscribeCollection(`admins`, (data) => {
                console.log("Refreshed admins: ", data);
                setAdmins(data);
            })

            const unsubscribeGroups = subscribeCollection(`institutions/${school.id}/groups`, (data) => {
                console.log("Refreshed school user groups: ", data)
                setGroups(data);
                setLoading(false);
                if (data)
                    setGroupsMap(new Map(data.map((item) => [item.id, item])));
                else
                    setGroupsMap(new Map());
            });

            return () => {
                unsubscribeUsers(); // Cleanup on unmount
                unsubscribeAdmins();
                unsubscribeGroups();
            }
        }
        else {
            console.log("Cleared users");
            setUsers([]);
            setUsersMap(new Map());
            setLoading(true);
        }
    }, [school])

    const deleteUser = async (uid) => {
        setLoading(true);
        try {
            // Initialize Firebase Functions
            const functions = getFunctions();
            const deleteUserFn = httpsCallable(functions, "deleteUser"); // Replace with your Cloud Function name

            // Call the Cloud Function
            const result = await deleteUserFn({ schoolId: school.id, uid: uid });

            // Process the result
            console.log('User deleted successfully.');
            showNotification(NotificationTypes.SUCCESS, `User deleted successfully.`);
            return result.data.token; // Return the token if needed
        } catch (error) {
            console.error('Error:', error);
            showNotification(NotificationTypes.DANGER, `Error: ${error.message}`);
        }
    }

    const createGroup = async (name, users) => {
        setLoading(true);
        try {
            await addDocument({ name, users, createdAt: serverTimestamp(), admin: user.id }, `institutions/${school.id}/groups`);
            showNotification(NotificationTypes.SUCCESS, `User group created successfully.`);
        }
        catch (e) {
            console.error(e);
            showNotification(NotificationTypes.DANGER, "An error occured.");
        }
    }

    const updateGroup = async (id, data) => {
        setLoading(true);
        try {
            await updateDocument(id, `institutions/${school.id}/groups`, data);
            showNotification(NotificationTypes.SUCCESS, `User group updated successfully.`);
        }
        catch (e) {
            console.error(e);
            showNotification(NotificationTypes.DANGER, "An error occured.");
        }
    }

    const deleteGroup = async (id) => {
        setLoading(true);
        try {
            await deleteDocument(id, `institutions/${school.id}/groups`);
            showNotification(NotificationTypes.SUCCESS, `User group deleted successfully.`);
        }
        catch (e) {
            console.error(e);
            showNotification(NotificationTypes.DANGER, "An error occured.");
        }
    }

    return (
        <UsersContext.Provider value={{ users, usersMap, admins, loading, deleteUser, createGroup, deleteGroup, groups, groupsMap, updateGroup }}>
            {children}
        </UsersContext.Provider>
    );
}

export { UsersProvider, UsersContext };