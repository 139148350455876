import React from "react";
import useAuth from "../../hooks/useAuth";
import useSidebar from "../../hooks/navigation/useSidebar";
import { usePopUp } from "../../hooks/usePopUp";
import { useNavigate } from "react-router-dom";

import {
    HiDocumentText,
    HiQuestionMarkCircle
} from "react-icons/hi";
import HelpPopUp from "../help/Help";
import Resources from "../resources/Resources";

/**
 * The sidebar component that's used throughout the entire app
 * TODO: Create a useSidebar hook to move React logic out of this UI code
 * @returns 
 */
const Sidebar = () => {
    const { authenticatedItems, logout, onItemPressed, isActive } = useSidebar();
    const { showPopUp } = usePopUp();
    const navigate = useNavigate();


    const handleHelpClick = () => {
        showPopUp(<HelpPopUp onClose={() => showPopUp(null)} />);
    };

    const handleResourcesClick = () => {
        showPopUp(<Resources onClose={() => showPopUp(null)}/>);
        //navigate("/resources");
    };

    return (
        <aside className="fixed top-0 left-0 w-64 h-screen pt-20 bg-white dark:bg-gray-800 border-r border-gray-200 dark:border-gray-700">
            <div className="overflow-y-auto py-5 px-3 h-full">
                <ul className="space-y-4">
                    {authenticatedItems.map((item, index) => (
                        <li key={index}>
                            <a
                                href="#"
                                onClick={(e) => {
                                    e.preventDefault();
                                    onItemPressed(item);
                                }}
                                className={`flex items-center p-3 text-base font-medium rounded-lg group ${isActive(item) ? 'bg-blue-600 text-white' : 'text-gray-700 dark:text-gray-300 hover:bg-blue-500 hover:text-white'}`}
                            >
                                <item.icon className={`w-6 h-6 `} />
                                <span className="ml-4">{item.label}</span>
                            </a>
                        </li>
                    ))}
                </ul>
                <ul className="pt-6 mt-6 space-y-4 border-t border-gray-200 dark:border-gray-700">
                    <li>
                        <a
                            href="#"
                            onClick={(e) => {
                                e.preventDefault();
                                handleResourcesClick();  // Handle click for Resources
                            }}
                            className="flex items-center p-3 text-base font-medium text-gray-700 rounded-lg dark:text-gray-300 bg-yellow-100 hover:bg-yellow-200 hover:text-gray-900 dark:bg-yellow-800 dark:hover:bg-yellow-700 dark:hover:text-white group"
                        >
                            <HiDocumentText className="w-6 h-6 text-yellow-500 group-hover:text-yellow-600 dark:text-yellow-400 dark:group-hover:text-yellow-300" />
                            <span className="ml-4">Resources</span>
                        </a>
                    </li>
                   
                    <li>
                        <a
                            href="#"
                            onClick={(e) => {
                                e.preventDefault();
                                handleHelpClick();
                            }}
                            className="flex items-center p-3 text-base font-medium text-gray-700 rounded-lg dark:text-gray-300 hover:bg-blue-500 hover:text-white group"
                        >
                            <HiQuestionMarkCircle className="w-6 h-6" />
                            <span className="ml-4">Help</span>
                        </a>
                    </li>
                    <li>
                        <a
                            href="#"
                            onClick={(e) => {
                                e.preventDefault();
                                logout();
                            }}
                            className="flex items-center p-3 text-base font-medium text-gray-700 rounded-lg dark:text-gray-300 hover:bg-blue-500 hover:text-white group"
                        >
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M16 17L21 12M21 12L16 7M21 12H9M9 3H7.8C6.11984 3 5.27976 3 4.63803 3.32698C4.07354 3.6146 3.6146 4.07354 3.32698 4.63803C3 5.27976 3 6.11984 3 7.8V16.2C3 17.8802 3 18.7202 3.32698 19.362C3.6146 19.9265 4.07354 20.3854 4.63803 20.673C5.27976 21 6.11984 21 7.8 21H9" stroke="#667085" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>

                            <span className="ml-4">Logout</span>
                        </a>
                    </li>
                </ul>



            </div>
            {/* <div className="hidden absolute bottom-0 left-0 justify-center p-4 space-x-4 w-full lg:flex bg-white dark:bg-gray-800 z-20">
                <button
                    onClick={toggleDarkMode}
                    id="theme-toggle"
                    type="button"
                    className="text-gray-500 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 rounded-lg text-sm p-2.5"
                >
                    <svg
                        id="theme-toggle-dark-icon"
                        className={darkMode ? "w-5 h-5" : "hidden w-5 h-5"}
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path d="M17.293 13.293A8 8 0 016.707 2.707a8.001 8.001 0 1010.586 10.586z"></path>
                    </svg>
                    <svg
                        id="theme-toggle-light-icon"
                        className={darkMode ? "hidden w-5 h-5" : "w-5 h-5"}
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M10 2a1 1 0 011 1v1a1 1 0 11-2 0V3a1 1 0 011-1zm4 8a4 4 0 11-8 0 4 4 0 018 0zm-.464 4.95l.707.707a1 1 0 001.414-1.414l-.707-.707a1 1 0 00-1.414 1.414zm2.12-10.607a1 1 0 010 1.414l-.706.707a1 1 0 11-1.414-1.414l.707-.707a1 1 0 011.414 0zM17 11a1 1 0 100-2h-1a1 1 0 100 2h1zm-7 4a1 1 0 011 1v1a1 1 0 11-2 0v-1a1 1 0 011-1zM5.05 6.464A1 1 0 106.465 5.05l-.708-.707a1 1 0 00-1.414 1.414l.707.707zm1.414 8.486l-.707.707a1 1 0 01-1.414-1.414l.707-.707a1 1 0 011.414 1.414zM4 11a1 1 0 100-2H3a1 1 0 000 2h1z"
                        ></path>
                    </svg>
                </button>
            </div> */}
        </aside>
    );
}

export default Sidebar;