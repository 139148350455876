import { useState, useEffect } from "react";
import {
    HiX,
    HiEye,
    HiEyeOff,
    HiOutlineCheck,
} from "react-icons/hi";
import RichTextEditor from "../shared/RichTextEditor";
import RichTextField from "../shared/RichTextField";
import GenericButton from "../shared/GenericButton";
import useResources from "../../hooks/useResources";
import useAuth from "../../hooks/useAuth";
import Roles from "../../utils/roles";
import logo from "../../assets/images/Courseta_Logo.png";

const Resources = ({ onClose }) => {
    const { user, role } = useAuth();
    const currentUserId = user?.id;

    const canEdit = role == Roles.ADMIN;

    const {
        resources,
        subcategories,
        loading,
        addCategory,
        addSubcategory,
        updateResource,
        updateSubcategory,
        fetchSubcategories,
        publishCategory,
        publishSubcategory,
        toggleCompletionCategory,
        toggleCompletionSubcategory,
    } = useResources();

    const [selectedCategory, setSelectedCategory] = useState(null);
    const [selectedSubcategory, setSelectedSubcategory] = useState(null);

    // When a new category is selected, fetch subcategories and reset subcategory selection.
    useEffect(() => {
        if (selectedCategory) {
            fetchSubcategories(selectedCategory.id);
            setSelectedSubcategory(null);
        }
    }, [selectedCategory, fetchSubcategories]);

    // For non-editing users, show only published items.
    const visibleCategories = resources.filter((cat) => canEdit || cat.published);
    const getVisibleSubcategories = (catId) => {
        const subs = subcategories[catId] || [];
        return subs.filter((sub) => canEdit || sub.published);
    };

    // Prepare content for the editor.
    const getEditorContent = (content) => {
        if (typeof content === "object" && content !== null) {
            return content;
        }
        return content || "";
    };

    // Force a remount of the editor when selection changes.
    const editorKey = selectedSubcategory
        ? `subcategory-${selectedSubcategory.id}`
        : selectedCategory
            ? `category-${selectedCategory.id}`
            : "empty";

    // Toggle publish for categories.
    const handlePublishCategory = (category) => {
        const newStatus = !category.published;
        publishCategory(category.id, newStatus);
        if (selectedCategory && selectedCategory.id === category.id) {
            setSelectedCategory({ ...category, published: newStatus });
        }
    };

    // Toggle publish for subcategories.
    const handlePublishSubcategory = (categoryId, subcategory) => {
        const newStatus = !subcategory.published;
        publishSubcategory(categoryId, subcategory.id, newStatus);
        if (selectedSubcategory && selectedSubcategory.id === subcategory.id) {
            setSelectedSubcategory({ ...subcategory, published: newStatus });
        }
    };

    // Toggle completion for the current item (category or subcategory).
    const handleToggleCompletion = () => {
        if (!currentUserId) return; // no user ID, no action
        if (selectedSubcategory) {
            toggleCompletionSubcategory(
                selectedCategory.id,
                selectedSubcategory.id,
                currentUserId
            );
        } else if (selectedCategory) {
            toggleCompletionCategory(selectedCategory.id, currentUserId);
        }
    };

    // Check if current user has completed the item.
    const isCompleted =
        selectedSubcategory
            ? selectedSubcategory.completed?.includes(currentUserId)
            : selectedCategory
                ? selectedCategory.completed?.includes(currentUserId)
                : false;

    return (
        <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center z-50 p-8">
            <div className="relative bg-white rounded-lg shadow-lg w-full max-w-8xl h-[80vh] flex flex-col">
                {/* Header */}
                <div className="bg-black text-white p-4 flex justify-between items-center rounded-t-lg">
                    <h2 className="text-xl font-semibold">Resources</h2>
                    <button onClick={onClose} className="text-white hover:text-gray-300">
                        <HiX className="h-6 w-6" />
                    </button>
                </div>

                {loading ? (
                    <div className="flex-1 flex items-center justify-center">
                        <p>Loading resources...</p>
                    </div>
                ) : (
                    <div className="flex flex-1 overflow-hidden">
                        {/* Sidebar with API Docs style */}
                        <aside className="w-72 bg-white border-r border-gray-200 p-6 overflow-y-auto">
                            <h4 className="text-lg font-bold text-gray-800 uppercase">
                                Resources
                            </h4>
                            {canEdit && (
                                <button
                                    onClick={addCategory}
                                    className="mt-4 text-blue-500 text-sm"
                                >
                                    + Add Category
                                </button>
                            )}

                            {/* Categories / Subcategories */}
                            <nav className="mt-6 space-y-4">
                                {visibleCategories.map((category) => (
                                    <div key={category.id}>
                                        <button
                                            onClick={() => {
                                                setSelectedCategory(category);
                                                setSelectedSubcategory(null);
                                            }}
                                            className={`
                        block w-full text-left 
                        text-md font-bold text-gray-800 
                        hover:text-blue-600 
                        ${selectedCategory?.id === category.id ? "text-blue-600" : ""}
                      `}
                                        >
                                            {category.name}
                                            {/* Checkmark if completed */}
                                            {!canEdit &&
                                                category.completed?.includes(currentUserId) && (
                                                    <HiOutlineCheck className="inline ml-2 text-green-500" />
                                                )}
                                        </button>

                                        {selectedCategory?.id === category.id && (
                                            <ul className="mt-2 ml-3 border-l border-gray-300 pl-3 space-y-1">
                                                {getVisibleSubcategories(category.id).length > 0 ? (
                                                    getVisibleSubcategories(category.id).map((subcat) => (
                                                        <li key={subcat.id} className="flex items-center justify-between">
                                                            <button
                                                                onClick={() => setSelectedSubcategory(subcat)}
                                                                className={`
              block w-full text-left 
              text-sm text-gray-600 hover:text-blue-600 
              ${selectedSubcategory?.id === subcat.id ? "text-blue-600 font-semibold" : ""}
            `}
                                                            >
                                                                {subcat.name}
                                                                {!canEdit &&
                                                                    subcat.completed?.includes(currentUserId) && (
                                                                        <HiOutlineCheck className="inline ml-2 text-green-500" />
                                                                    )}
                                                            </button>
                                                        </li>
                                                    ))
                                                ) : (
                                                    <p className="text-xs text-gray-500 mt-1">No subcategories.</p>
                                                )}
                                                {canEdit && (
                                                    <button
                                                        onClick={() => addSubcategory(category.id)}
                                                        className="mt-2 text-blue-500 text-xs"
                                                    >
                                                        + Add Subcategory
                                                    </button>
                                                )}
                                            </ul>
                                        )}

                                    </div>
                                ))}
                            </nav>
                        </aside>

                        {/* Main content */}
                        <main className="flex-1 p-6 bg-white overflow-y-auto">
                            {/* If user is not editing, show the completion slider for the selected item */}
                            {!canEdit && (selectedSubcategory || selectedCategory) && (
                                <div className="flex items-center space-x-2 mb-4">
                                    <span
                                        className={`w-28 text-sm ${isCompleted ? "text-green-500" : "text-gray-700"
                                            }`}
                                    >
                                        {isCompleted ? "Completed!" : "Not Completed"}
                                    </span>
                                    <div
                                        className={`w-12 h-6 rounded-full cursor-pointer ${isCompleted ? "bg-green-500" : "bg-gray-300"
                                            } relative`}
                                        onClick={handleToggleCompletion}
                                    >
                                        <div
                                            className={`w-6 h-6 bg-white rounded-full absolute top-0 transition-transform ${isCompleted ? "translate-x-6" : "translate-x-0"
                                                }`}
                                        ></div>
                                    </div>
                                </div>
                            )}

                            {/* Publish toggles (for editing users) */}
                            {canEdit && selectedSubcategory && (
                                <GenericButton
                                    className="mb-4"
                                    label={selectedSubcategory.published ? "Unpublish" : "Publish"}
                                    handleClick={() =>
                                        handlePublishSubcategory(
                                            selectedCategory.id,
                                            selectedSubcategory
                                        )
                                    }
                                    icon={selectedSubcategory.published ? <HiEyeOff /> : <HiEye />}
                                />
                            )}
                            {canEdit && !selectedSubcategory && selectedCategory && (
                                <GenericButton
                                    className="mb-4"
                                    label={selectedCategory.published ? "Unpublish" : "Publish"}
                                    handleClick={() => handlePublishCategory(selectedCategory)}
                                    icon={selectedCategory.published ? <HiEyeOff /> : <HiEye />}
                                />
                            )}

                            {/* Editor or read-only field */}
                            {selectedSubcategory ? (
                                canEdit ? (
                                    <RichTextEditor
                                        key={editorKey}
                                        initialContent={getEditorContent(selectedSubcategory.content)}
                                        onSave={(data) => {
                                            updateSubcategory(selectedCategory.id, selectedSubcategory.id, {
                                                content: data,
                                            });
                                            setSelectedSubcategory((prev) => ({
                                                ...prev,
                                                content: data,
                                            }));
                                        }}
                                    />
                                ) : (
                                    <RichTextField
                                        key={editorKey}
                                        initialContent={getEditorContent(selectedSubcategory.content)}
                                    />
                                )
                            ) : selectedCategory ? (
                                canEdit ? (
                                    <RichTextEditor
                                        key={editorKey}
                                        initialContent={getEditorContent(selectedCategory.content)}
                                        onSave={(data) => {
                                            updateResource(selectedCategory.id, { content: data });
                                            setSelectedCategory((prev) => ({
                                                ...prev,
                                                content: data,
                                            }));
                                        }}
                                    />
                                ) : (
                                    <RichTextField
                                        key={editorKey}
                                        initialContent={getEditorContent(selectedCategory.content)}
                                    />
                                )
                            ) : (
                                <div className="flex flex-col items-center justify-center h-full text-center text-gray-500">
                                    <img
                                        src={logo}
                                        alt="Your Logo"
                                        className="w-64 h-54 mb-4 opacity-75"
                                    />
                                    <h2 className="text-xl font-semibold mb-2">Welcome to Courseta Resources</h2>
                                    <p className="mb-4 max-w-sm">
                                        Select a category to learn more about our app and its functionalities!
                                    </p>
                                </div>
                            )}
                        </main>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Resources;
