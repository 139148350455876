import logo from "../../assets/images/Courseta_Logo.png";

export const emailTemplate = (user, email, content, schoolName) => {

  return `
    <!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
    <html dir="ltr" lang="en">
    <head>
      <meta content="text/html; charset=UTF-8" http-equiv="Content-Type" />
      <style>
        body {
          background-color: #ffffff;
          font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
          margin: 0;
          padding: 0.5rem;
        }
        .email-container {
          max-width: 465px;
          border: 1px solid #eaeaea;
          margin: 40px auto;
          padding: 20px;
          border-radius: 0.25rem;
        }
        .email-content {
          font-size: 14px;
          line-height: 24px;
          color: #000000;
        }
        .button {
          background-color: #000000;
          color: #ffffff;
          padding: 12px 20px;
          border-radius: 0.25rem;
          font-weight: 600;
          text-decoration: none;
          display: inline-block;
          margin-top: 32px;
          text-align: center;
        }
      </style>
    </head>
    <body>
      <div style="display:none; font-size:1px; line-height:1px; max-height:0px; max-width:0px; opacity:0; overflow:hidden;">
        Support Ticket Opened
      </div>
      <table align="center" role="presentation" style="background-color: #ffffff; width: 100%; max-width: 465px; border: 1px solid #eaeaea; border-radius: 0.25rem; margin: 40px auto; padding: 20px;">
        <tr>
          <td style="padding: 20px; text-align: center;">
          <img src="https://www.coursetavr.com/assets/images/Courseta_Logo.png" alt="CoursetaVR" style="max-width: 100px; margin-bottom: 20px;">
            <h2 style="color: #000000; margin: 30px 0;">Support Ticket Opened</h2>
            <div class="email-content">
              <p>Hello <strong>${user}</strong>,</p>
              <p>Your support ticket has been opened with the following details:</p>
              <p>${content}</p>
              <p>We will get in touch with you as soon as possible!</p>
              <p style="font-size: 12px; line-height: 24px; color: #666666; margin-top: 26px;">Email: ${email} , Institution: ${schoolName} </p>
            </div>
            <p style="font-size: 12px; line-height: 24px; color: #666666; margin-top: 26px;">
              If you did not request this support ticket or believe this to be an error, please ignore this email or <a href="kirkpatrickw@tactilevr.com" style="color: #3498db;">contact support</a>.
            </p>
          </td>
        </tr>
      </table>
    </body>
    </html>
    
    `;
};
