import React, { useRef, useState } from "react";
import html2pdf from "html2pdf.js";
import logo from '../../../assets/images/Courseta_Logo.png';
import hospitalImage from '../../../assets/images/HospitalRoom.jpg';
import { MetaHumanImages } from "../../shared/MetaHumanImages";
import { AACNDomains, AACNCoreCompetencies, AACNSubCompetencies } from "../../../utils/ScriptingEnumLibrary";

/* Reusable Section Wrapper with a Blue Header */
const BlueSection = ({ title, children, rightContent }) => {
  return (
    <section className="mb-8">
      {/* Blue heading area */}
      <div className="bg-blue-600 text-white px-4 py-2 rounded-t-md flex items-center justify-between">
        <h2 className="text-xl font-semibold">{title}</h2>
        {/* If you need something aligned to the right (like an image), pass it in */}
        {rightContent && <div className="ml-4">{rightContent}</div>}
      </div>

      {/* White content area with border continuing from the blue header */}
      <div className="border border-t-0 border-blue-600 p-4 rounded-b-md">
        {children}
      </div>
    </section>
  );
};

/* PrintablePage1: Cover Page */
const PrintablePage1 = React.forwardRef(({ sections, scenario, patient }, ref) => {
  const images = MetaHumanImages(patient?.metahuman);

  return (
    <div ref={ref} className="page bg-white w-full p-8 mb-8 border">
      {/* Page Header (Logo + Title + Scenario Name) */}
      <header className="flex justify-between items-center mb-8">
        <img src={logo} alt="Logo" className="h-16 object-contain" />
        <div className="text-right">
          <h1 className="text-2xl font-bold">Courseta Case Scenario</h1>
          {scenario?.name && (
            <p className="text-lg text-gray-700 mt-1">{scenario.name}</p>
          )}
        </div>
      </header>

      {/* Scenario Summary */}
      {sections.scenarioSummary && (
        <BlueSection
          title="Scenario Summary"
          rightContent={
            images?.faceImage && (
              <img
                src={images.faceImage}
                alt="character"
                className="w-24 h-24 object-cover rounded-md border-2 border-white"
              />
            )
          }
        >
          <p className="italic">{scenario?.summary}</p>
          {/* <div className="mt-4 flex justify-between text-lg">
            <span>
              <strong>Estimated Scenario Time:</strong> ________
            </span>
            <span>
              <strong>Counted Clinical Time:</strong> ________
            </span>
          </div> */}
        </BlueSection>
      )}

      {/* Learning Objectives */}
      {sections.learningObjectives && (
        <BlueSection title="Learning Objectives">
          <ul className="list-disc list-inside ml-6 mt-2 text-lg">
            {scenario?.learningObjectives?.map((entry, index) => (
              <li key={index}>{entry}</li>
            ))}
          </ul>
        </BlueSection>
      )}

      {/* Curriculum Alignment */}
      {sections.curriculumAlignment && (
        <BlueSection title="Curriculum Alignment">
          <div className="mt-2">
            <p className="text-lg font-semibold">Clinical Outcomes</p>
            <ul className="list-disc list-inside ml-6 mt-2 text-base">
              {scenario?.clinicalOutcomes?.map((entry, index) => (
                <li key={index}>{entry}</li>
              ))}
            </ul>
          </div>

          <div className="mt-6">
            <p className="text-lg font-semibold">Core Competencies</p>
            {scenario?.aacnCompetencies?.map((entry, index) => {
              const domainName = Object.keys(AACNDomains)[entry.domain];
              const coreCompName = Object.keys(
                AACNCoreCompetencies[entry.domain]
              )[entry.coreCompetency];
              const subCompName = Object.keys(
                AACNSubCompetencies[entry.domain][entry.coreCompetency]
              )[entry.subCompetency];

              return (
                <div key={index} className="border rounded-md p-2 my-2">
                  <p className="text-sm">
                    <strong>Domain:</strong> {domainName}
                  </p>
                  <p className="text-sm">
                    <strong>Core Competency:</strong> {coreCompName}
                  </p>
                  <p className="text-sm">
                    <strong>Sub-Competency:</strong> {subCompName}
                  </p>
                </div>
              );
            })}
          </div>
        </BlueSection>
      )}
    </div>
  );
});

/* PrintablePage2: Patient Details & Environment */
const PrintablePage2 = React.forwardRef(({ sections, scenario, patient }, ref) => {
  const timeStamp = patient.chartSummary?.birthday
    ? patient.chartSummary.birthday * 1000
    : null;

  return (
    <div ref={ref} className="page bg-white w-full p-8 mb-8 border">
      {/* Patient Information */}
      {sections.patientInformation && (
        <BlueSection title="Patient Information">
          <div className="mt-4 grid grid-cols-2 gap-4 text-lg">
            <div>
              <p>
                <strong>Patient Name:</strong> {patient.chartSummary?.name}
              </p>
              <p>
                <strong>Gender:</strong> {patient.chartSummary?.gender}
              </p>
              <p>
                <strong>Birthday:</strong>{" "}
                {timeStamp && new Date(timeStamp).toLocaleString()}
              </p>
              <p>
                <strong>Age:</strong>{" "}
                {timeStamp && new Date(timeStamp).toLocaleDateString()}
              </p>
            </div>
            <div>
              <p>
                <strong>Weight:</strong> {patient.chartSummary?.weight}
              </p>
              <p>
                <strong>Height:</strong> {patient.chartSummary?.height}
              </p>
              <p>
                <strong>Code Status:</strong> {patient.chartSummary?.codeStatus}
              </p>
              <p>
                <strong>Allergies:</strong> {patient.chartSummary?.allergies}
              </p>
              <p>
                <strong>Admitting Diagnosis:</strong>{" "}
                {patient.chartSummary?.admittingDiagnosis}
              </p>
            </div>
          </div>
        </BlueSection>
      )}

      {/* Equipment
      {sections.equipment && (
        <BlueSection title="Equipment">
          <p className="mt-2 italic text-lg">[Insert Equipment Details Here]</p>
        </BlueSection>
      )} */}

      {/* Environment */}
      {sections.environment && (
        <BlueSection title="Environment">
          <img
            src={hospitalImage}
            alt="Hospital Room"
            className="w-36 h-36 rounded-lg mt-2"
          />
          <p className="mt-4 font-semibold text-lg text-gray-700">
            Inpatient Hospital Med-Surg/ICU
          </p>
        </BlueSection>
      )}
    </div>
  );
});

/* PrintablePage3: Prebriefing, Debriefing, Clinical Judgement, Documentation */
const PrintablePage3 = React.forwardRef(({ sections, scenario }, ref) => {
  return (
    <div ref={ref} className="page bg-white w-full p-8 mb-8 border">
      {/* Prebriefing
      {sections.prebriefing && (
        <BlueSection title="Prebriefing">
          <p className="mt-2 text-lg">[Insert Prebriefing Details Here]</p>
        </BlueSection>
      )} */}

      {/* Debriefing */}
      {sections.debriefing && scenario.debriefing && (
        <BlueSection title="Debriefing">
          <ol className="list-decimal list-inside mt-4 text-lg">
            {scenario.debriefing.map((item, idx) => (
              <li key={idx}>{item}</li>
            ))}
          </ol>
        </BlueSection>
      )}

      {/* Clinical Judgement */}
      {sections.clinicalJudgement && scenario.debriefing_2 && (
        <BlueSection title="Clinical Judgement">
          {scenario.debriefing_2.map((q, idx) => (
            <div key={idx} className="mb-4">
              <p className="text-lg font-semibold">{q.question}</p>
              <ul className="list-disc list-inside mt-2 text-lg">
                {q.answers.map((answer, answerIdx) => (
                  <li
                    key={answerIdx}
                    className={
                      answerIdx === q.correctAnswer ? "text-green-600" : ""
                    }
                  >
                    {answer}
                  </li>
                ))}
              </ul>
              <p className="mt-2 italic">Rationale: {q.rationale}</p>
            </div>
          ))}
        </BlueSection>
      )}

      {/* Documentation */}
      {sections.documentation && scenario.documentation && (
        <BlueSection title="Documentation">
          {scenario.documentation.map((doc, idx) => (
            <div key={idx} className="mb-4 border p-2 rounded">
              <p className="text-xl font-bold">{doc.title}</p>
              <p className="mt-1">
                <strong>Description:</strong> {doc.description}
              </p>
              <p className="mt-1">
                <strong>Options:</strong> {doc.options && doc.options.join(", ")}
              </p>
              <p className="mt-1">
                <strong>Correct Answers:</strong>{" "}
                {doc.correctAnswers && doc.correctAnswers.join(", ")}
              </p>
              {doc.needExplanation && doc.explanation && (
                <p className="mt-1">
                  <strong>Explanation:</strong> {doc.explanation}
                </p>
              )}
              {doc.imageUrl && (
                <img src={doc.imageUrl} alt={doc.title} className="w-32 h-32 mt-2" />
              )}
              {doc.videoUrl && (
                <p className="mt-1">
                  <strong>Video:</strong>{" "}
                  <a href={doc.videoUrl} className="text-blue-600 underline">
                    {doc.videoUrl}
                  </a>
                </p>
              )}
            </div>
          ))}
        </BlueSection>
      )}
    </div>
  );
});

/* PrintablePage4: Orders & Results */
const PrintablePage4 = React.forwardRef(({ sections, scenario, patient }, ref) => {
  return (
    <div ref={ref} className="page bg-white w-full p-8 mb-8 border">
      {/* Orders */}
      {sections.orders && patient.orders && (
        <BlueSection title="Orders">
          {Object.entries(patient.orders).map(([orderCategory, ordersArray]) => (
            <div key={orderCategory} className="mb-4">
              <h3 className="text-lg font-semibold mb-2">
                {orderCategory.charAt(0).toUpperCase() + orderCategory.slice(1)}
              </h3>
              <ul className="list-disc list-inside mt-2 text-base ml-6">
                {ordersArray.map((order, index) => (
                  <li key={index} className="mb-2">
                    <p>
                      <strong>Name:</strong> {order.name}
                    </p>
                    <p>
                      <strong>Physician Required:</strong>{" "}
                      {order.physicianRequired ? "Yes" : "No"}
                    </p>
                    <p>
                      <strong>Present:</strong> {order.present ? "Yes" : "No"}
                    </p>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </BlueSection>
      )}

      {/* Results */}
      {sections.results && patient.results && (
        <BlueSection title="Results">
          {Object.entries(patient.results).map(([category, resultData]) => {
            const isDirectResult = resultData && resultData.hasOwnProperty("lab");
            return (
              <div key={category} className="mb-4">
                <h3 className="text-lg font-semibold mb-2">{category}</h3>
                {isDirectResult ? (
                  <div className="ml-4 text-base">
                    <p>
                      <strong>Lab:</strong> {resultData.lab}
                    </p>
                    <p>
                      <strong>Reference:</strong> {resultData.reference}
                    </p>
                    <p>
                      <strong>Result:</strong> {resultData.result}
                    </p>
                    <p>
                      <strong>Status:</strong> {resultData.status}
                    </p>
                  </div>
                ) : (
                  Object.entries(resultData).map(([subKey, subResult]) => (
                    <div key={subKey} className="ml-4 mt-2">
                      <h4 className="text-base font-semibold">{subKey}</h4>
                      <div className="ml-4 text-base">
                        <p>
                          <strong>Lab:</strong> {subResult.lab}
                        </p>
                        <p>
                          <strong>Reference:</strong> {subResult.reference}
                        </p>
                        <p>
                          <strong>Result:</strong> {subResult.result}
                        </p>
                        <p>
                          <strong>Status:</strong> {subResult.status}
                        </p>
                      </div>
                    </div>
                  ))
                )}
              </div>
            );
          })}
        </BlueSection>
      )}
    </div>
  );
});

/* PrintablePage5: Reflection, MAR, Rubrics */
const PrintablePage5 = React.forwardRef(({ sections, scenario, patient }, ref) => {
  return (
    <div ref={ref} className="page bg-white w-full p-8 mb-8 border">
      {/* Reflection */}
      {sections.reflection && scenario.reflection && (
        <BlueSection title="Reflection">
          <ol className="list-decimal list-inside mt-4 text-lg">
            {scenario.reflection.map((item, idx) => (
              <li key={idx}>{item}</li>
            ))}
          </ol>
        </BlueSection>
      )}

      {/* MAR */}
      {sections.mar && patient.mar && (
        <BlueSection title="Medication Administration Record (MAR)">
          {patient.mar.map((entry, idx) => (
            <div key={idx} className="mb-4 p-2 border rounded">
              <p>
                <strong>Medication:</strong> {entry.medication}
              </p>
              <p>
                <strong>Dose:</strong> {entry.dose}
              </p>
              <p>
                <strong>Frequency:</strong> {entry.frequency}
              </p>
              <p>
                <strong>Route:</strong> {entry.route}
              </p>
              <p>
                <strong>Status:</strong> {entry.status}
              </p>
              <p>
                <strong>Unit:</strong> {entry.unit}
              </p>
              <p>
                <strong>Due:</strong> {entry.due ? "Yes" : "No"}
              </p>
            </div>
          ))}
        </BlueSection>
      )}

      {/* Rubrics */}
      {/* {sections.rubrics && patient.rubrics && (
        <BlueSection title="Rubrics">
          <ul className="list-disc list-inside mt-4 text-lg">
            {patient.rubrics.map((item, idx) => (
              <li key={idx}>{item}</li>
            ))}
          </ul>
        </BlueSection>
      )} */}
    </div>
  );
});

const OverviewTab = ({ scenario, patient, isPopup = false }) => {
  const [sections, setSections] = useState({
    scenarioSummary: true,
    learningObjectives: true,
    curriculumAlignment: true,
    patientInformation: true,
    equipment: true,
    environment: true,
    orders: true,
    results: true,
    prebriefing: true,
    debriefing: true,
    clinicalJudgement: true,
    reflection: true,
    documentation: true,
    mar: true,
    rubrics: true,
  });

  const printContainerRef = useRef();

  const handleDownloadPDF = () => {
    const element = printContainerRef.current;
    const options = {
      margin: 0.5,
      filename: "PrebriefingDocument.pdf",
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
    };
    html2pdf().from(element).set(options).save();
  };

  const handleToggle = (sectionKey) => {
    setSections((prev) => ({
      ...prev,
      [sectionKey]: !prev[sectionKey],
    }));
  };

  const toggleList = [
    { key: "scenarioSummary", label: "Scenario Summary" },
    { key: "learningObjectives", label: "Learning Objectives" },
    { key: "curriculumAlignment", label: "Curriculum Alignment" },
    { key: "patientInformation", label: "Patient Information" },
    { key: "equipment", label: "Equipment" },
    { key: "environment", label: "Environment" },
    { key: "orders", label: "Orders" },
    { key: "results", label: "Results" },
    { key: "prebriefing", label: "Prebriefing" },
    { key: "debriefing", label: "Debriefing" },
    { key: "clinicalJudgement", label: "Clinical Judgement" },
    { key: "reflection", label: "Reflection" },
    { key: "documentation", label: "Documentation" },
    { key: "mar", label: "MAR" },
    { key: "rubrics", label: "Rubrics" },
  ];

  return (
    <div className="p-4 bg-white border dark:bg-gray-800 min-h-screen w-full h-full">
      <div className="max-w-7xl mx-auto p-6">
        <h2 className="text-2xl font-semibold mb-4">Overview</h2>
        <p className="text-gray-600 text-lg mb-6">
          Make sure you filled in everything
        </p>

        <div className="flex items-start gap-8">
          {/* Left column: PDF pages */}
          <div className="flex-1" ref={printContainerRef}>
            <PrintablePage1 sections={sections} scenario={scenario} patient={patient} />
            <PrintablePage2 sections={sections} scenario={scenario} patient={patient} />
            <PrintablePage3 sections={sections} scenario={scenario} patient={patient} />
            <PrintablePage4 sections={sections} scenario={scenario} patient={patient} />
            <PrintablePage5 sections={sections} scenario={scenario} patient={patient} />
          </div>

          {/* Right column: toggles & PDF button (hidden when printing) */}
          {!isPopup && (
            <div className="print:hidden w-72 flex-shrink-0">
              {/* Download PDF at the top */}
              <button
                onClick={handleDownloadPDF}
                className="bg-blue-600 text-white w-full py-3 rounded text-xl mb-6"
              >
                Download PDF
              </button>

              <h3 className="text-lg font-semibold mb-2">Sections to Include</h3>
              <div className="space-y-2">
                {toggleList.map(({ key, label }) => (
                  <label key={key} className="flex items-center text-base">
                    <input
                      type="checkbox"
                      checked={sections[key]}
                      onChange={() => handleToggle(key)}
                      className="mr-2"
                    />
                    {label}
                  </label>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default OverviewTab;
