import React, { useEffect, useState } from 'react';
import { TabsUnderlined } from "../../shared/TabNavigation";
import SideSteps from '../../shared/SideSteps';
import useScenarios from '../../../hooks/useScenarios';
import { HiPlus, HiTrash, HiUpload } from 'react-icons/hi';
import useScenarioCustomization from '../../../hooks/useScenarioCustomization';
import { getFileUrl } from '../../../firebase/storage';
import ScenarioFile from './ScenarioFile';
import { transformYoutubeLink } from '../../../utils/utils';
import { NotificationTypes } from '../../../utils/notifications';
import { useNotification } from '../../../hooks/useNotification';
import GenericButton from '../../shared/GenericButton';
import { AACNDomains, AACNCoreCompetencies, AACNSubCompetencies } from '../../../utils/ScriptingEnumLibrary';

const steps = ['Scenario Info', 'Curriculum'];

const CurriculumTab = () => {
  const [step, setStep] = useState(0);

  return (
    <div className="flex space-x-8 min-h-screen">
      {/* SideSteps Navigation */}
      <div className="w-1/5">
        <SideSteps currentStep={step} steps={steps} setCurrentStep={setStep} />
      </div>

      {/* Main Content Area */}
      <div className="flex flex-col flex-grow">
        {/* Content based on the current step */}
        <div className="flex-grow mt-4">
          {step === 0 && <ScenarioInfo />}
          {step === 1 && <Curriculum />}
        </div>
      </div>
    </div>
  );
};

const ScenarioInfo = () => {
  const [activeTab, setActiveTab] = useState(0);
  const { scenario, setField } = useScenarioCustomization();

  // Remove the "Learning Objectives" tab—only use "Info"
  return (
    <div className="flex flex-col flex-grow">
      <div className="container p-6 bg-white rounded-lg shadow-md dark:bg-gray-800 dark:text-gray-200">
        <TabsUnderlined
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          tabs={["Info"]}
        />
        <div className="container mx-auto my-8 p-6 bg-white rounded-lg shadow-md dark:bg-gray-800 dark:text-gray-200">
          <h2 className="text-2xl font-semibold text-gray-900 dark:text-white mb-6">Scenario Details</h2>

          {/* Scenario Name */}
          <div className="mb-6">
            <label htmlFor="name" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
              Scenario Name
            </label>
            <input
              id="name"
              type="text"
              value={scenario.name}
              onChange={(e) => setField("name", e.target.value)}
              className="w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:text-gray-200"
              placeholder="Enter the scenario name"
            />
          </div>

          {/* Scenario Summary */}
          <div className="mb-6">
            <label htmlFor="summary" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
              Scenario Summary
            </label>
            <textarea
              id="summary"
              value={scenario.summary}
              onChange={(e) => setField("summary", e.target.value)}
              rows="4"
              className="w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:text-gray-200"
              placeholder="Enter a summary for the scenario"
            />
          </div>

          <ScenarioFile fieldName={"info"} />
          <ScenarioPicture />

          {/* Append Learning Objectives Input at the bottom of the Info tab */}
          <div className="mt-8">
            <h2 className="text-2xl font-semibold text-gray-900 dark:text-white mb-6">Learning Objectives</h2>
            {scenario.learningObjectives?.map((objective, index) => (
              <div key={index} className="flex items-center mb-2">
                <input
                  type="text"
                  id={index}
                  value={objective}
                  onChange={(e) => {
                    const updated = [...scenario.learningObjectives];
                    updated[index] = e.target.value;
                    setField('learningObjectives', updated);
                  }}
                  className="w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:text-gray-200"
                  placeholder="Enter learning objective"
                />
                <button
                  onClick={() => {
                    const updated = scenario.learningObjectives.filter((_, i) => i !== index);
                    setField('learningObjectives', updated);
                  }}
                  className="ml-2 p-2 bg-red-500 text-white rounded"
                >
                  <HiTrash className="h-5 w-5" />
                </button>
              </div>
            ))}
            <div className="flex justify-center w-full gap-4 mt-4">
              <GenericButton
                handleClick={() => {
                  const updated = [...(scenario.learningObjectives || []), ""];
                  setField('learningObjectives', updated);
                }}
                icon={<HiPlus />}
                label={"Add Objective"}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const ScenarioPicture = () => {
  const { scenario, getFile, setFile } = useScenarioCustomization();
  const [preview, setPreview] = useState(null);

  useEffect(() => {
    const fetchPicture = async () => {
      if (scenario.thumbnail) {
        const url = await getFileUrl(getFile("thumbnail"));
        setPreview(url);
      }
    };
    fetchPicture();
  }, [scenario.thumbnail, getFile]);

  const handleThumbnailChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFile(file, "thumbnail");
      setPreview(URL.createObjectURL(file));
    }
  };

  return (
    <div className="mb-4">
      <label htmlFor="thumbnail" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
        Scenario Thumbnail
      </label>
      <input
        id="thumbnail"
        type="file"
        accept="image/*"
        onChange={handleThumbnailChange}
        className="w-full text-gray-900 bg-gray-50 border rounded-lg file:mr-4 file:py-2 file:px-4 file:border-0 file:text-sm file:font-semibold file:bg-blue-50 file:text-blue-600 hover:file:bg-blue-100 dark:bg-gray-700 dark:text-white"
      />
      {preview && (
        <div className="mt-4">
          Current:
          <img
            src={preview}
            alt="Thumbnail Preview"
            className="w-1/3 h-64 aspect-square object-cover rounded-lg border dark:border-gray-600"
          />
        </div>
      )}
    </div>
  );
};

const Curriculum = () => {
  // Now add a new tab "Info" in addition to Prebriefing, Debriefing, and Reflection.
  const [activeTab, setActiveTab] = useState(0);
  const tabs = ["Prebriefing", "Debriefing", "Reflection", "Alignment"];

  return (
    <div className="flex flex-col flex-grow">
      <div className="container p-6 bg-white rounded-lg shadow-md dark:bg-gray-800 dark:text-gray-200">
        <TabsUnderlined activeTab={activeTab} setActiveTab={setActiveTab} tabs={tabs} />
        {tabs[activeTab] === "Prebriefing" && (
          <div>
            <Prebriefing />
            <PrebriefingVideo />
          </div>
        )}
        {tabs[activeTab] === "Debriefing" && (
          <div>
            <Debriefing />
            <Debriefing2 />
          </div>
        )}
        {tabs[activeTab] === "Reflection" && (
          <div>
            <Reflection />
          </div>
        )}
        {tabs[activeTab] === "Alignment" && <CurriculumInfo />}
      </div>
    </div>
  );
};

// New component for the Curriculum "Info" tab
const CurriculumInfo = () => {
  const { scenario, setField } = useScenarioCustomization();

  return (
    <div className="p-6">
      {/* Clinical Outcome Section */}
      <div className="mb-8">
        <h2 className="text-2xl font-semibold text-gray-900 dark:text-white mb-6">Clinical Outcome</h2>
        {scenario.clinicalOutcomes?.map((outcome, index) => (
          <div key={index} className="flex items-center mb-2">
            <input
              type="text"
              id={index}
              value={outcome}
              onChange={(e) => {
                const updated = [...scenario.clinicalOutcomes];
                updated[index] = e.target.value;
                setField('clinicalOutcomes', updated);
              }}
              className="w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:text-gray-200"
              placeholder="Enter clinical outcome"
            />
            <button
              onClick={() => {
                const updated = scenario.clinicalOutcomes.filter((_, i) => i !== index);
                setField('clinicalOutcomes', updated);
              }}
              className="ml-2 p-2 bg-red-500 text-white rounded"
            >
              <HiTrash className="h-5 w-5" />
            </button>
          </div>
        ))}
        <div className="flex justify-center w-full gap-4 mt-4">
          <GenericButton
            handleClick={() => {
              const updated = [...(scenario.clinicalOutcomes || []), ""];
              setField('clinicalOutcomes', updated);
            }}
            icon={<HiPlus />}
            label={"Add Clinical Outcome"}
          />
        </div>
      </div>

      {/* AACN Core Competencies Section */}
      <div>
        <h2 className="text-2xl font-semibold text-gray-900 dark:text-white mb-6">
          AACN Core Competencies
        </h2>
        {scenario.aacnCompetencies?.map((item, index) => {
          // Use string conversion when accessing enums.
          const domainKey = String(item.domain);
          const coreObj = AACNCoreCompetencies[domainKey] || {};
          const subObj =
            AACNSubCompetencies[domainKey] &&
            AACNSubCompetencies[domainKey][String(item.coreCompetency)]
              ? AACNSubCompetencies[domainKey][String(item.coreCompetency)]
              : {};

          return (
            <div key={index} className="mb-4 border p-4 rounded-md">
              {/* Domain Dropdown */}
              <div className="flex items-center space-x-2 mb-2">
                <label className="w-1/4">AACN Domain</label>
                <select
                  value={item.domain}
                  onChange={(e) => {
                    const newDomain = e.target.value; // string value
                    // Get first core competency for the selected domain.
                    const coreEntries = AACNCoreCompetencies[String(newDomain)]
                      ? Object.entries(AACNCoreCompetencies[String(newDomain)])
                      : [];
                    const newCore = coreEntries.length > 0 ? coreEntries[0][1] : "";
                    // Get first sub competency for this domain and core.
                    const subEntries =
                      AACNSubCompetencies[String(newDomain)] &&
                      AACNSubCompetencies[String(newDomain)][String(newCore)]
                        ? Object.entries(AACNSubCompetencies[String(newDomain)][String(newCore)])
                        : [];
                    const newSub = subEntries.length > 0 ? subEntries[0][1] : "";
                    const updated = [...scenario.aacnCompetencies];
                    updated[index] = {
                      domain: Number(newDomain),
                      coreCompetency: Number(newCore),
                      subCompetency: Number(newSub)
                    };
                    setField("aacnCompetencies", updated);
                  }}
                  className="w-full p-2 border rounded-md"
                >
                  {Object.entries(AACNDomains).map(([name, num]) => (
                    <option key={num} value={num}>
                      {`${Number(num) + 1}. ${name}`}
                    </option>
                  ))}
                </select>
              </div>

              {/* Core Competency Dropdown */}
              <div className="flex items-center space-x-2 mb-2">
                <label className="w-1/4">AACN Core Competency</label>
                <select
                  value={item.coreCompetency}
                  onChange={(e) => {
                    const newCore = e.target.value;
                    const subEntries =
                      AACNSubCompetencies[String(item.domain)] &&
                      AACNSubCompetencies[String(item.domain)][String(newCore)]
                        ? Object.entries(AACNSubCompetencies[String(item.domain)][String(newCore)])
                        : [];
                    const newSub = subEntries.length > 0 ? subEntries[0][1] : "";
                    const updated = [...scenario.aacnCompetencies];
                    updated[index] = {
                      ...updated[index],
                      coreCompetency: Number(newCore),
                      subCompetency: Number(newSub)
                    };
                    setField("aacnCompetencies", updated);
                  }}
                  className="w-full p-2 border rounded-md"
                >
                  {Object.entries(coreObj).map(([desc, value]) => (
                    <option key={value} value={value}>
                      {`${Number(item.domain) + 1}.${Number(value) + 1} ${desc}`}
                    </option>
                  ))}
                </select>
              </div>

              {/* Sub Competency Dropdown */}
              <div className="flex items-center space-x-2 mb-2">
                <label className="w-1/4">AACN Sub Competency</label>
                <select
                  value={item.subCompetency}
                  onChange={(e) => {
                    const newSub = e.target.value;
                    const updated = [...scenario.aacnCompetencies];
                    updated[index] = { ...updated[index], subCompetency: Number(newSub) };
                    setField("aacnCompetencies", updated);
                  }}
                  className="w-full p-2 border rounded-md"
                >
                  {Object.entries(subObj).map(([desc, value]) => (
                    <option key={value} value={value}>
                      {`${Number(item.domain) + 1}.${Number(item.coreCompetency) + 1}.${Number(value) + 1} ${desc}`}
                    </option>
                  ))}
                </select>
              </div>

              {/* Delete Button */}
              <div className="flex justify-end">
                <button
                  onClick={() => {
                    const updated = [...scenario.aacnCompetencies];
                    updated.splice(index, 1);
                    setField("aacnCompetencies", updated);
                  }}
                  className="p-2 bg-red-500 text-white rounded"
                >
                  <HiTrash className="h-5 w-5" />
                </button>
              </div>
            </div>
          );
        })}
        <div className="flex justify-center w-full gap-4 mt-4">
          <GenericButton
            handleClick={() => {
              const domainKeys = Object.keys(AACNDomains);
              if (domainKeys.length === 0) return;
              const defaultDomainKey = domainKeys[0];
              const defaultDomainNum = AACNDomains[defaultDomainKey];
              const coreEntries = AACNCoreCompetencies[String(defaultDomainNum)]
                ? Object.entries(AACNCoreCompetencies[String(defaultDomainNum)])
                : [];
              const defaultCore = coreEntries.length > 0 ? coreEntries[0][1] : "";
              const subEntries =
                AACNSubCompetencies[String(defaultDomainNum)] &&
                AACNSubCompetencies[String(defaultDomainNum)][String(defaultCore)]
                  ? Object.entries(AACNSubCompetencies[String(defaultDomainNum)][String(defaultCore)])
                  : [];
              const defaultSub = subEntries.length > 0 ? subEntries[0][1] : "";
              const newItem = {
                domain: Number(defaultDomainNum),
                coreCompetency: Number(defaultCore),
                subCompetency: Number(defaultSub)
              };
              const updated = [...(scenario.aacnCompetencies || []), newItem];
              setField("aacnCompetencies", updated);
            }}
            icon={<HiPlus />}
            label={"Add AACN Core Competency"}
          />
        </div>
      </div>
    </div>
  );
};

const Prebriefing = () => {
  const { scenario, setFile } = useScenarios();
  const [url, setUrl] = useState("");

  return (
    <div className="container mx-auto my-8 p-4 bg-white rounded-lg shadow-md dark:bg-gray-800">
      <h2 className="text-lg font-semibold text-gray-900 dark:text-white mb-4">Prebriefing</h2>
      <div className="flex items-center">
        <ScenarioFile fieldName={"prebriefing"} />
      </div>
    </div>
  );
};

const PrebriefingVideo = () => {
  const { scenario, setField } = useScenarioCustomization();

  const handleUrlChange = (e) => {
    const transformedUrl = transformYoutubeLink(e.target.value);
    setField('prebriefingVideo', transformedUrl);
  };

  return (
    <div className="container mx-auto my-8 p-4 bg-white rounded-lg shadow-md dark:bg-gray-800">
      <h2 className="text-lg font-semibold text-gray-900 dark:text-white mb-4">Prebriefing Video Link</h2>
      <div className="flex flex-col items-start gap-4 mb-2">
        <input
          type="text"
          value={scenario.prebriefingVideo || ""}
          onChange={handleUrlChange}
          placeholder="Paste the YouTube video link here"
          className="p-2 border rounded-l flex-grow w-full"
        />
        <iframe
          width="560"
          height="315"
          src={scenario.prebriefingVideo}
          title="YouTube video preview"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
    </div>
  );
};

const Debriefing = () => {
  const { scenario, setField } = useScenarioCustomization();

  const handleDelete = (index) => {
    const updated = scenario.debriefing?.filter((_, i) => i !== index);
    setField('debriefing', updated);
  };

  const handleAdd = () => {
    const updated = [...(scenario.debriefing || []), ""];
    setField('debriefing', updated);
  };

  const handleEdit = (e) => {
    let updated = [...scenario.debriefing];
    updated[e.target.id] = e.target.value;
    setField('debriefing', updated);
  };

  return (
    <div className="container mx-auto my-8 p-4 dark:bg-gray-800">
      <h2 className="text-lg mb-4 font-semibold text-gray-900 dark:text-white">Debriefing</h2>
      {scenario.debriefing?.map((field, index) => (
        <div key={index} className="flex items-center mb-2">
          <input
            type="text"
            id={index}
            value={field}
            onChange={(e) => handleEdit(e)}
            className="p-2 border rounded flex-grow"
            placeholder="Enter question"
          />
          <button onClick={() => handleDelete(index)} className="ml-2 p-2 bg-red-500 text-white rounded">
            <HiTrash className="h-5 w-5" />
          </button>
        </div>
      ))}
      <div className="flex justify-center w-full gap-4 mt-4">
        <GenericButton handleClick={handleAdd} icon={<HiPlus />} label={"Add Question"} />
      </div>
    </div>
  );
};

const Debriefing2 = () => {
  const { scenario, setField } = useScenarioCustomization();

  const handleDelete = (index) => {
    const updated = scenario.debriefing_2.filter((_, i) => i !== index);
    setField('debriefing_2', updated);
  };

  const handleAdd = () => {
    const newQuestion = {
      question: '',
      answers: ['', '', '', ''],
      correctAnswer: 0,
      rationale: ''
    };
    setField('debriefing_2', [...scenario.debriefing_2 ?? [], newQuestion]);
  };

  const handleEdit = (e, type, questionIndex, answerIndex) => {
    let updated = [...scenario.debriefing_2];
    if (type === 'question') {
      updated[questionIndex].question = e.target.value;
    } else if (type === 'answer') {
      updated[questionIndex].answers[answerIndex] = e.target.value;
    } else if (type === 'correctAnswer') {
      updated[questionIndex].correctAnswer = Number(e.target.value);
    } else if (type === 'rationale') {
      updated[questionIndex].rationale = e.target.value;
    }
    setField('debriefing_2', updated);
  };

  return (
    <div className="container mx-auto my-8 p-4 dark:bg-gray-800">
      <h2 className="text-lg font-semibold text-gray-900 dark:text-white">Clinical Judgment Debriefing</h2>
      {scenario.debriefing_2?.map((question, questionIndex) => (
        <div key={questionIndex} className="mb-6 bg-white dark:bg-gray-700 p-4 rounded-md shadow-sm">
          <input
            type="text"
            id={`question_${questionIndex}`}
            placeholder="Enter question"
            value={question.question}
            onChange={(e) => handleEdit(e, 'question', questionIndex)}
            className="p-2 border rounded w-full"
          />
          <textarea
            id={`rationale_${questionIndex}`}
            placeholder="Enter rationale"
            value={question.rationale || ''}
            onChange={(e) => handleEdit(e, 'rationale', questionIndex)}
            className="p-2 border rounded w-full"
          />
          {question.answers.map((answer, answerIndex) => (
            <div key={answerIndex} className="flex items-center mt-2">
              <input
                type="radio"
                id={`correctAnswer_${questionIndex}_${answerIndex}`}
                value={Number(answerIndex)}
                name={`correctAnswer_${questionIndex}`}
                checked={question.correctAnswer === answerIndex}
                onChange={(e) => handleEdit(e, 'correctAnswer', questionIndex)}
                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 rounded mr-4"
              />
              <input
                type="text"
                id={`answer_${questionIndex}_${answerIndex}`}
                placeholder={`Answer ${answerIndex + 1}`}
                value={answer}
                onChange={(e) => handleEdit(e, 'answer', questionIndex, answerIndex)}
                className="p-2 border rounded flex-grow"
              />
            </div>
          ))}
          <button
            onClick={() => handleDelete(questionIndex)}
            className="w-full py-2 bg-red-500 text-white rounded mt-4 hover:bg-red-600"
          >
            Delete Question
          </button>
        </div>
      ))}
      <div className="flex justify-center">
        <GenericButton handleClick={handleAdd} icon={<HiPlus />} label={"Add Question"} />
      </div>
    </div>
  );
};

const Reflection = () => {
  const { scenario, setField } = useScenarioCustomization();

  const handleDelete = (index) => {
    const updated = scenario.reflection?.filter((_, i) => i !== index);
    setField('reflection', updated);
  };

  const handleAdd = () => {
    const updated = [...(scenario.reflection ?? []), ""];
    setField('reflection', updated);
  };

  const handleEdit = (e) => {
    let updated = [...scenario.reflection];
    updated[e.target.id] = e.target.value;
    setField('reflection', updated);
  };

  return (
    <div className="container mx-auto my-8 p-4 dark:bg-gray-800">
      {scenario.reflection?.map((field, index) => (
        <div key={index} className="flex items-center mb-4 space-x-2">
          <input
            type="text"
            id={index}
            value={field}
            onChange={handleEdit}
            placeholder="Enter question"
            className="p-2 border rounded flex-grow shadow-sm"
          />
          <button onClick={() => handleDelete(index)} className="p-2 bg-red-500 text-white rounded hover:bg-red-700 transition-colors">
            <HiTrash className="h-5 w-5" />
          </button>
        </div>
      ))}
      <div className="flex items-center space-x-2">
        <GenericButton handleClick={handleAdd} icon={<HiPlus />} label={"Add Question"} />
      </div>
    </div>
  );
};

export default CurriculumTab;
