import React, { useState, useEffect } from 'react';
import useScenarios from '../../../hooks/useScenarios';
import useScenarioCustomization from '../../../hooks/useScenarioCustomization';
import { useNotification } from '../../../hooks/useNotification';
import { NotificationTypes } from '../../../utils/notifications';
import Accordion from '../../shared/Accordion.js';
import { LabTests, prettyPrintEnum } from '../../../utils/ScriptingEnumLibrary.js';
import { Dropdown } from 'flowbite-react';

// Default templates for categories
const defaultCategories = {
    'CBC (Complete Blood Count)': {
        WBC: { result: 0.0, reference: '5-10', status: 'Final', lab: 'General Lab' },
        RBC: { result: 0.0, reference: '4.3-5.6', status: 'Final', lab: 'General Lab' },
        Hemoglobin: { result: 0.0, reference: '12-16', status: 'Final', lab: 'General Lab' },
        Hematocrit: { result: '42%', reference: '40-55%', status: 'Final', lab: 'General Lab' },
        Platelets: { result: 0.0, reference: '150-400', status: 'Final', lab: 'General Lab' }
    },
    'CMP (Comprehensive Metabolic Panel)': {
        Sodium: { result: 0.0, reference: '135-145', status: 'Final', lab: 'General Lab' },
        Potassium: { result: 0.0, reference: '3.5-5.0', status: 'Final', lab: 'General Lab' },
        Chloride: { result: 0.0, reference: '97-107', status: 'Final', lab: 'General Lab' },
        Calcium: { result: 9.5, reference: '8.5-10.2', status: 'Final', lab: 'General Lab' },
        Glucose: { result: 90, reference: '70-100', status: 'Final', lab: 'General Lab' },
        BUN: { result: 0.0, reference: '6-20', status: 'Final', lab: 'General Lab' },
        Creatinine: { result: 0.0, reference: '0.6-1.3', status: 'Final', lab: 'General Lab' },
        GFR: { result: 0.0, reference: '>60', status: 'Final', lab: 'General Lab' },
        AST: { result: 0.0, reference: '8-33', status: 'Final', lab: 'General Lab' },
        ALT: { result: 0.0, reference: '4-36', status: 'Final', lab: 'General Lab' },
        ALP: { result: 0.0, reference: '20-130', status: 'Final', lab: 'General Lab' },
        Albumin: { result: 0.0, reference: '3.4-5.4', status: 'Final', lab: 'General Lab' },
        'Total Bilirubin': { result: 0.0, reference: '0.1-1.2', status: 'Final', lab: 'General Lab' },
        'Total Protein': { result: 0.0, reference: '6.0-8.3', status: 'Final', lab: 'General Lab' },
        CO2: { result: 0.0, reference: '23-29', status: 'Final', lab: 'General Lab' }
    },
    'Lipid Panel': {
        'Total Cholesterol': { result: 180, reference: '<200 mg/dL', status: 'Final', lab: 'General Lab' },
        HDL: { result: 50, reference: '>50 mg/dL', status: 'Final', lab: 'General Lab' },
        LDL: { result: 0.0, reference: '<100 mg/dL', status: 'Final', lab: 'General Lab' },
        Triglycerides: { result: 0.0, reference: '<150 mg/dL', status: 'Final', lab: 'General Lab' }
    },
    'Thyroid Panel': {
        TSH: { result: 0.0, reference: '0.5-4 mIU/L', status: 'Final', lab: 'General Lab' },
        'Free T4': { result: 0.0, reference: '0.8-1.8 ng/dL', status: 'Final', lab: 'General Lab' },
        'Total T3': { result: 0.0, reference: '80-180 ng/dL', status: 'Final', lab: 'General Lab' },
        'Total T4': { result: 0.0, reference: '5-12 µg/dL', status: 'Final', lab: 'General Lab' }
    },
    'Urinalysis': {
        Color: { result: '', reference: 'Light Yellow', status: 'Final', lab: 'General Lab' },
        Clarity: { result: '', reference: 'Clear', status: 'Final', lab: 'General Lab' },
        pH: { result: 0.0, reference: '4.5-8', status: 'Final', lab: 'General Lab' },
        'Specific Gravity': { result: 0.0, reference: '1.005-1.025', status: 'Final', lab: 'General Lab' },
        Glucose: { result: 0.0, reference: '<130 mg/dL', status: 'Final', lab: 'General Lab' },
        Ketones: { result: '', reference: 'None', status: 'Final', lab: 'General Lab' },
        Nitrites: { result: '', reference: 'Negative', status: 'Final', lab: 'General Lab' },
        'Leukocyte Esterase': { result: '', reference: 'Negative', status: 'Final', lab: 'General Lab' },
        Bilirubin: { result: '', reference: 'Negative', status: 'Final', lab: 'General Lab' },
        Urobilinogen: { result: 0.0, reference: '0.5-1 mg/dL', status: 'Final', lab: 'General Lab' },
        Blood: { result: '', reference: '<3 RBCs', status: 'Final', lab: 'General Lab' },
        Protein: { result: 0.0, reference: '<150 mg/dL', status: 'Final', lab: 'General Lab' },
        RBCs: { result: '', reference: '< RBCs/hpf', status: 'Final', lab: 'General Lab' },
        WBCs: { result: '', reference: '<2-5 WBCs/hpf', status: 'Final', lab: 'General Lab' },
        'Squamous epithelial cells': { result: '', reference: '<15-20/hpf', status: 'Final', lab: 'General Lab' },
        Casts: { result: '', reference: '0-5 hyaline casts/hpf', status: 'Final', lab: 'General Lab' },
        Crystals: { result: '', reference: 'Occasionally', status: 'Final', lab: 'General Lab' },
        Bacteria: { result: '', reference: 'None', status: 'Final', lab: 'General Lab' },
        Yeast: { result: '', reference: 'None', status: 'Final', lab: 'General Lab' }
    }
};


const ResultRow = ({ categoryName, resultName }) => {
    const { patient, setPatientField } = useScenarioCustomization();

    const updateResult = (category, fieldName, fieldValue) => {
        const updatedResults = { ...patient.results };
        updatedResults[category][fieldName] = fieldValue;
        setPatientField('results', updatedResults);
    };

    const removeResult = (category, fieldName) => {
        const updatedResults = { ...patient.results };
        delete updatedResults[category][fieldName];
        setPatientField('results', updatedResults);
    };

    const result = patient.results[categoryName][resultName];

    const handleChange = (e) => {
        const { name, value } = e.target;
        updateResult(categoryName, resultName, { ...result, [name]: String(value) });
    };

    const handleNameChange = (e) => {
        const updatedResults = { ...patient.results };
        const currentResult = { ...updatedResults[categoryName][resultName] };
        delete updatedResults[categoryName][resultName];
        updateResult(categoryName, e.target.value, currentResult);
    };

    return (
        <tr>
            <td className="border px-4 py-2">
                <input
                    type="text"
                    name="name"
                    value={resultName}
                    onChange={handleNameChange}
                    className="w-full border rounded px-2 py-1 focus:outline-none focus:ring focus:border-blue-300"
                />
            </td>
            <td className="border px-4 py-2">
                <input
                    type="text"
                    name="result"
                    value={result.result || ''}
                    onChange={handleChange}
                    placeholder="Result"
                    className="w-full border rounded px-2 py-1 focus:outline-none focus:ring focus:border-blue-300"
                />
            </td>
            <td className="border px-4 py-2">
                <input
                    type="text"
                    name="reference"
                    value={result.reference || ''}
                    onChange={handleChange}
                    placeholder="Reference"
                    className="w-full border rounded px-2 py-1 focus:outline-none focus:ring focus:border-blue-300"
                />
            </td>
            <td className="border px-4 py-2">
                <input
                    type="text"
                    name="status"
                    value={result.status || ''}
                    onChange={handleChange}
                    placeholder="Status"
                    className="w-full border rounded px-2 py-1 focus:outline-none focus:ring focus:border-blue-300"
                />
            </td>
            <td className="border px-4 py-2">
                <input
                    type="text"
                    name="lab"
                    value={result.lab || ''}
                    onChange={handleChange}
                    placeholder="Lab"
                    className="w-full border rounded px-2 py-1 focus:outline-none focus:ring focus:border-blue-300"
                />
            </td>
            <td className="border px-4 py-2 text-center">
                <button
                    onClick={() => removeResult(categoryName, resultName)}
                    className="text-red-500 hover:text-red-700"
                >
                    &#10005;
                </button>
            </td>
        </tr>
    );
};

// Dropdown for adding/removing default templates (using Flowbite)
const TemplateDropdown = () => {
    const { patient, setPatientField } = useScenarioCustomization();
    const categories = patient.results || {};

    const toggleTemplate = (templateName) => {
        const updatedResults = { ...categories };
        if (updatedResults[templateName]) {
            delete updatedResults[templateName];
        } else {
            updatedResults[templateName] = defaultCategories[templateName];
        }
        setPatientField('results', updatedResults);
    };

    return (
        <Dropdown label="Templates" arrowIcon={true}>
            {Object.keys(defaultCategories).map((templateName) => (
                <Dropdown.Item key={templateName}>
                    <label className="flex items-center w-full cursor-pointer">
                        <input
                            type="checkbox"
                            className="mr-2"
                            checked={!!categories[templateName]}
                            onChange={() => toggleTemplate(templateName)}
                        />
                        <span>{templateName}</span>
                    </label>
                </Dropdown.Item>
            ))}
        </Dropdown>
    );
};

const Results = () => {
    const { patient, setPatientField } = useScenarioCustomization();
    const [newCategoryName, setNewCategoryName] = useState('');
    const { showNotification } = useNotification();
    const categories = patient.results || {};

    useEffect(() => {
        if (!patient.results) {
            setPatientField('results', {});
        }
    }, [patient.results, setPatientField]);

    const addResultCategory = (categoryName) => {
        const updatedResults = { ...patient.results };
        if (updatedResults[categoryName]) {
            showNotification(NotificationTypes.WARNING, `Category: ${categoryName} already exists.`);
            return;
        }
        updatedResults[categoryName] = {};
        setPatientField('results', updatedResults);
    };

    return (
        <div className="p-6 bg-white rounded-md border">
            <div className="flex justify-between items-center mb-4">
                <h2 className="text-2xl font-semibold">Results</h2>
                <TemplateDropdown />
            </div>
            <p className="mb-4 text-gray-600">Enter the information you want inside the patient EMR.</p>
            {Object.keys(categories).map((categoryName) => (
                <ResultTable key={categoryName} categoryName={categoryName} />
            ))}
            <form
                className="mt-4"
                onSubmit={(e) => {
                    e.preventDefault();
                    addResultCategory(newCategoryName);
                    setNewCategoryName('');
                }}
            >
                <input
                    type="text"
                    value={newCategoryName}
                    onChange={(e) => setNewCategoryName(e.target.value)}
                    placeholder="Enter new category name"
                    className="border rounded w-1/2 px-4 py-2 mr-2 focus:outline-none focus:ring focus:border-blue-300"
                    required
                />
                <button
                    type="submit"
                    className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600"
                >
                    Add New Category
                </button>
            </form>
        </div>
    );
};

const ResultTable = ({ categoryName }) => {
    const { patient, setPatientField } = useScenarioCustomization();
    const [newResultName, setNewResultName] = useState('');
    const categories = patient.results;

    const updateResultCategoryCollected = (categoryName, notCollected) => {
        const updatedResults = { ...patient.results };
        updatedResults[categoryName].notCollected = notCollected;
        setPatientField('results', updatedResults);
    };

    const updateResultCategoryLabtype = (categoryName, labtype) => {
        const updatedResults = { ...patient.results };
        updatedResults[categoryName].labType = labtype;
        setPatientField('results', updatedResults);
    };

    const removeResultCategory = (categoryName) => {
        const updatedResults = { ...patient.results };
        delete updatedResults[categoryName];
        setPatientField('results', updatedResults);
    };

    const addResult = (categoryName) => {
        if (newResultName.trim() && !categories[categoryName][newResultName]) {
            const newResult = { result: '', reference: '', status: '', lab: '' };
            const updatedResults = { ...patient.results };
            updatedResults[categoryName][newResultName] = newResult;
            setPatientField('results', updatedResults);
            setNewResultName('');
        }
    };

    return (
        <Accordion
            title={categoryName}
            onRemove={() => removeResultCategory(categoryName)}
            isRemovable={true}
        >
            <div className="mb-4 flex space-x-2">
                <div className="inline-flex w-full items-center">
                    <div className="flex items-center w-1/3">
                        <span className="mr-2">Not Collected</span>
                        <label className="relative inline-flex items-center cursor-pointer">
                            <input
                                type="checkbox"
                                checked={!categories[categoryName]?.notCollected}
                                onChange={() =>
                                    updateResultCategoryCollected(
                                        categoryName,
                                        !categories[categoryName]?.notCollected
                                    )
                                }
                                className="sr-only peer"
                            />
                            <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-2 peer-focus:ring-blue-500 peer-focus:ring-opacity-50 rounded-full peer-checked:bg-blue-500 peer-checked:after:translate-x-5 after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all"></div>
                        </label>
                        <span className="ml-2">Collected</span>
                    </div>
                    {categories[categoryName]?.notCollected && (
                        <select
                            value={categories[categoryName]?.labType ?? 0}
                            onChange={(e) =>
                                updateResultCategoryLabtype(categoryName, Number(e.target.value))
                            }
                            className="w-2/3 p-2 border border-gray-300 rounded-md dark:bg-gray-700 dark:text-gray-200"
                        >
                            {Object.entries(LabTests).map(([key, value]) => (
                                <option key={value} value={value}>
                                    {prettyPrintEnum(key)}
                                </option>
                            ))}
                        </select>
                    )}
                </div>
            </div>
            <table className="w-full table-auto mb-4">
                <thead>
                    <tr>
                        <th className="border px-4 py-2 text-left">Name</th>
                        <th className="border px-4 py-2 text-left">Result</th>
                        <th className="border px-4 py-2 text-left">Reference Range</th>
                        <th className="border px-4 py-2 text-left">Status</th>
                        <th className="border px-4 py-2 text-left">Category</th>
                        <th className="border px-4 py-2 text-center">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {Object.keys(categories[categoryName])
                        .filter((f) => f !== 'notCollected' && f !== 'labType')
                        .map((resultKey) => (
                            <ResultRow key={resultKey} categoryName={categoryName} resultName={resultKey} />
                        ))}
                </tbody>
            </table>
            <div className="mt-4 flex items-center">
                <input
                    type="text"
                    value={newResultName}
                    onChange={(e) => setNewResultName(e.target.value)}
                    placeholder="Enter new result name"
                    className="border rounded w-1/2 px-4 py-2 mr-2 focus:outline-none focus:ring focus:border-blue-300"
                />
                <button
                    onClick={() => addResult(categoryName)}
                    className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                >
                    Add New Result
                </button>
            </div>
        </Accordion>
    );
};

export default Results;
