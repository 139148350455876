import { useContext } from "react";
import { ResourcesContext } from "../contexts/ResourcesContext";

/**
 * Hook for managing Resources data from context
 * @returns Resources state and Firestore operations
 */
const useResources = () => {
  const {
    resources,
    subcategories,
    loading,
    addCategory,
    addSubcategory,
    updateResource,
    updateSubcategory,
    fetchSubcategories,
    publishCategory,
    publishSubcategory,
    toggleCompletionCategory,
    toggleCompletionSubcategory,
  } = useContext(ResourcesContext);

  return {
    resources,
    subcategories,
    loading,
    addCategory,
    addSubcategory,
    updateResource,
    updateSubcategory,
    fetchSubcategories,
    publishCategory,
    publishSubcategory,
    toggleCompletionCategory,
    toggleCompletionSubcategory,
  };
};

export default useResources;
