import React, { useState, useEffect } from 'react'
import { usePopUp } from '../../hooks/usePopUp';
import { csvReadFileWithRequiredColumns, xlsxReadFileWithRequiredColumns } from '../../utils/spreadsheet';
import { validateUserInviteSpreadsheet } from '../../utils/batchInviteUsers';
import useAuth from '../../hooks/useAuth';
import useCourses from '../../hooks/useCourses';
import { HiPlus } from 'react-icons/hi';
import useRegistrations from '../../hooks/useRegistrations';
import useSchool from '../../hooks/useSchool';
import { getFileUrl } from '../../firebase/storage';
import { getDownloadURL } from 'firebase/storage';
import { useNotification } from '../../hooks/useNotification';

export const BatchInviteUsers = () => {
    const { showPopUp } = usePopUp();
    const { showNotification } = useNotification();
    const [resultsNode, setResultsNode] = useState((<></>));
    const [fileIsValid, setFileIsValid] = useState(false);
    const [inviteData, setInviteData] = useState([]);

    const { addRegistration } = useRegistrations();
    const { school } = useSchool();

    const { role } = useAuth();
    const { courses } = useCourses();

    const handleOpen = async (material) => {
        console.log("Opening template: ", material);
        const url = await getFileUrl(`students_multi_invite_template.xlsx`);
        if (url) {
            window.open(url, '_blank');
        } else {
            showNotification('danger', 'Error: Unable to open file');
        }
    }

    const handleFileChange = async (event) => {
        setFileIsValid(false);
        const file = event.target.files?.[0];
        let sheetResults = undefined;
        if (file !== undefined) {
            sheetResults = await validateUserInviteSpreadsheet(file, role, courses, "");
            // Go over potential error messages for each worksheet
            let sheetErrMsgs = []
            sheetResults.forEach(result => {
                if (result.err !== undefined) {
                    sheetErrMsgs.push(result.err);
                }
            });
            if (sheetErrMsgs.length > 0) {
                setResultsNode(
                    <>
                        <div className="flex flex-col space-y-2 mt-8 h-96 overflow-y-auto border border-gray-200 rounded-lg p-4">
                            <b className="text-red-500">This file contains {sheetErrMsgs.length} error(s):</b>
                            {sheetErrMsgs.map((msg, i) => {
                                return (
                                    <p>{i + 1}) {msg}</p>
                                );
                            })}
                        </div>
                    </>
                );
                return;
            } else {
                setFileIsValid(true);
                let invData = [];
                sheetResults.forEach(result => {
                    const data = result.data;
                    invData.push(
                        {
                            firstName: data.firstName,
                            lastName: data.lastName,
                            email: data.email,
                            role: data.role,
                            courses: data.courses,
                        }
                    );
                });
                setInviteData(invData);
                setResultsNode(
                    <div className="flex flex-col space-y-2 mt-8 border border-gray-200 rounded-lg p-4">
                        <b className="text-green-500">This file contains no errors</b>
                    </div>
                );
            }
        } else {
            setResultsNode(
                <></>
            );
        }
    }

    const handleInviteAll = async (e) => {
        e.preventDefault();
        showPopUp(null);

        inviteData.forEach(async invite => {
            await addRegistration({
                firstName: invite.firstName,
                lastName: invite.lastName,
                email: invite.email,
                role: invite.role,
                courses: invite.courses,
                schoolId: school.id
            });
        });

        // TODO MAANDAG: Show notification upon success/failure!

        setInviteData([]);
    }

    return (
        <div
            id="createUserdefaultModal"
            aria-hidden="true"
            className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
        >
            <div className="relative w-full max-w-2xl p-6 bg-white rounded-lg shadow-lg dark:bg-gray-800">
                {/* Header */}
                <div className="flex justify-between items-center pb-4 border-b dark:border-gray-600">
                    <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                        Batch Invite Users
                    </h3>
                    <button
                        onClick={() => showPopUp(null)}
                        type="button"
                        className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg p-1.5 dark:hover:bg-gray-600 dark:hover:text-white"
                    >
                        <svg
                            aria-hidden="true"
                            className="w-5 h-5"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fillRule="evenodd"
                                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                clipRule="evenodd"
                            ></path>
                        </svg>
                        <span className="sr-only">Close modal</span>
                    </button>
                </div>

                {/* Description */}
                <div className="mt-2 mb-4 text-sm text-gray-700 dark:text-gray-300">
                    <p>
                        A batch invite file should be a spreadsheet in <strong>.xlsx</strong> or <strong>.csv</strong>
                        format, with the following columns in the top-most row: <strong>firstName, lastName, email,
                            role,</strong> and <strong>courseNames</strong>. Courses should be separated by a semicolon (;).
                    </p>
                </div>

                {/* File Upload Section */}
                <div className="mb-4">
                    <label
                        htmlFor="spreadsheetFileInput"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                        Please choose a spreadsheet file (.xlsx, .csv)
                    </label>
                    <input
                        type="file"
                        id="spreadsheetFileInput"
                        name="spreadsheetFileInput"
                        accept=".xlsx, .csv"
                        className="w-full px-3 py-2 border rounded-md focus:ring focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                        onChange={handleFileChange}
                    />
                </div>

                {/* Results Display */}
                {resultsNode}

                {/* Actions */}
                <div className="flex justify-between mt-6">
                    <button
                        type="button"
                        onClick={handleOpen}
                        className="inline-flex items-center text-blue-600 hover:underline"
                    >
                        Download Template
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="2"
                            stroke="currentColor"
                            className="w-5 h-5 ml-2"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M4 16v1a2 2 0 002 2h12a2 2 0 002-2v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
                            />
                        </svg>
                    </button>
                    <button
                        className="px-5 py-2.5 text-sm font-medium text-white bg-blue-600 disabled:bg-blue-400 rounded-lg hover:bg-blue-700 focus:ring-4 focus:ring-blue-300 dark:bg-blue-500 dark:hover:bg-blue-600"
                        onClick={handleInviteAll}
                        disabled={!fileIsValid}
                    >
                        Invite Users
                    </button>
                </div>
            </div>
        </div>
    );

}