export const getEnumKeyByValue = (enumObj, value) => {
    return Object.keys(enumObj).find(key => enumObj[key] === value);
};

export const prettyPrintEnum = (value) => {
    const result = value
        .replace(/_/g, ' ') // Replace underscores with spaces
        .replace(/([A-Z])/g, ' $1') // Add space before uppercase letters
        .trim(); // Remove extra spaces at the start and end
    return result.charAt(0).toUpperCase() + result.slice(1).toLowerCase(); // Capitalize the first letter
};

export const StatusType = {
    NotGiven: 0,
    Given: 1
}

export const O2Flowmeter = {
    '2 LPM': 0,
    '4 LPM': 1,
    '6 LPM': 2,
    // Add other flow rates as necessary
};

export const ConditionFrequency = {
    '5 Sec': 0,
    '10 Sec': 1,
    '30 Sec': 2,
    // Add other frequencies as necessary
};

export const NodeTypes = {
    EventNode: 0,
    EffectNode: 1,
    CheckNode: 2,
    DelayNode: 3
};

export const EventTypes = {
    SimulationStarted: 0,
    WhenMedicationAdministered: 1,
    WhenIvAdministered: 2,
    WhenOxygenAdminstered: 3,
    WhenBloodPressureTaken: 4,
    WhenTemperatureTaken: 5,
    WhenGlucoseTaken: 6,
    WhenDoctorCalled: 7,
    WhenLabCollected: 8,
    WhenPatientRepositioned: 9,
    WhenUserTalked: 10,
    WhenSimulationSubmitted: 11,
    WhenRestraintsApplied: 12,
    WhenRestraintsRemoved: 13,
    WhenAssessmentPerformed: 14,
    WhenUserWashedHands: 15,
    WhenGlovesApplied: 16
};

export const AssessmentType = {
    AuscultatingLungs: 0,
    AuscultatingHeartSounds: 1,
    AuscultatingBowelSounds: 2,
    PalpatingPulses: 3,
    PalpatingAbdomen: 4,
    HandsNihssClipboard: 5,
    UsesPenlight: 6,
    MeasuresFundalHeight: 7,
    MeasuresFetalHeartTones: 8,
    MassagesUterineFundus: 9,
    PalpatesLowerExtremities: 10,
    OpensLabResults: 11,
    OpensEmrNotes: 12,
    OpensOrders: 13
}

export const PatientPositionTypes = {
    Fowlers: 0,
    SemiFowlers: 1,
    Supine: 2,
    HighFowlers: 3
}

export const EffectTypes = {
    ChangeVital: 0,
    NewLabResult: 1,
    ChangePhysiology: 2,
    ChangeBehavior: 3,
    SetCustomField: 4,
    AiCharacterActions: 5,
    NewOrder: 6,
    NewPhoneCall: 7,
    Grade: 8,
    NewCondition: 9,
}

export const CheckTypes = {
    IfVitalIs: 0,
    IfIvBeingGiven: 1,
    IfMedicationWasGiven: 2,
    IfOxygenBeingGiven: 3,
    IfPatientIsPositioned: 4,
    IfLabWasCollected: 5,
    IfIvPushedInTime: 6,
    IfCustomFieldIs: 7,
    IfIvWasChangedBy: 8,
    IfUserSaid: 9,
    IfBehaviorIs: 10,
    IfDoctorCalled: 11,
    IfAssessmentPerformed: 12
}

export const AICharacterActions = {
    Say: 0,
    Instruction: 1,
    CustomTrigger: 2
}

export const InworldTarget = {
    Patient: 0,
    Doctor: 1,
    Family: 2
}

export const PhysiologySystems = {
    Neurological: 0,
    Pulmonary: 1,
    Cardiac: 2,
    Gastrointestinal: 3,
    Integumentary: 4,
    Musculoskeletal: 5,
    Maternity: 6
}

export const NeurologicalFindings = {
    PupilShape: 0,
    PupilReaction: 1,
    EyeGaze: 2,
    EyeSclera: 3,
    FacialSymmetry: 4,
    MotorFunction: 5,
    Ataxia: 6,
    SensoryFunction: 7,
    Speech: 8
}

export const MaternityFindings = {
    FundalHeight: 0,
    FetalHeartRate: 1,
    VaginalHemorrhage: 2
}

export const Speech = {
    Normal: 0,
    Slurred: 1,
    Incomprehensible: 2
}

export const EyeSclera = {
    Normal: 0,
    Jaundice: 1,
    Redness: 2
}

export const EyeGaze = {
    Normal: 0,
    LeftGazeNeglect: 1,
    RightGazeNeglect: 2,
    CompleteGazeDeviation: 3
}

export const PupilReaction = {
    Brisk: 0,
    Sluggish: 1,
    NonReactive: 2
}

export const PupilShape = {
    Normal: 0,
    Dilated: 1,
    Irregular: 2,
    Pinpoint: 3
}

export const DirectionType = {
    Left: 0,
    Right: 1
}

export const SensoryFunction = {
    Normal: 0,
    Decreased: 1
}

export const Ataxia = {
    Normal: 0,
    Ataxia: 1
}

export const PatientExtremity = {
    LeftArm: 0,
    RightArm: 1,
    LeftLeg: 2,
    RightLeg: 3,
}

export const MotorFunction = {
    NoDrift: 0,
    Drift: 1,
    SomeEffortAgainstGravity: 2,
    NoMovement: 3
}

export const FacialSymmetry = {
    Normal: 0,
    RightFacialDroop: 1,
    LeftFacialDroop: 2,
    TongueDeviationLeft: 3,
    TongueDeviationRight: 4,
    CompleteFacialParalysis: 5
}

export const SensoryLocations = {
    LeftFacial: 0,
    RightFacial: 1,
    LeftArm: 2,
    RightArm: 3,
    LeftFoot: 4,
    RightFoot: 5
}

export const PulmonaryFindings = {
    ChestShape: 0,
    RespiratoryPattern: 1,
    ChestExpansion: 2,
    LungAuscultation: 3,
    Drooling: 4
}

export const CardiacFindings = {
    HeartAuscultation: 0,
    HeartRhythm: 1,
    Pulses: 2,
    Edema: 3,
}

export const GastrointestinalFindings = {
    BowelSounds: 0,
    AbdominalPalpation: 1,
    AbdominalContour: 2,
}

export const IntegumentaryFindings = {
    GeneralizedRashes: 0,
    Wounds: 1,
    DeepVeinThrombosis: 2
}

export const DeepVeinThrombosis = {
    LeftLeg: 0,
    RightLeg: 1
}

export const MusculoskeletalFindings = {
    HandGrip: 0,
    FootPlantarFlexion: 1,
    FootDorsalFlexion: 2,
    Clubbing: 3,
}

export const StrengthTypes = {
    Strong: 0,
    Weak: 1,
    Absent: 2
}

export const ClubbingTypes = {
    No: 0,
    Yes: 1
}

export const AbdominalContourTypes = {
    Flat: 0,
    Distended: 1,
    Obese: 2,
    Scaphoid: 3,
    Pregnant: 4
}

export const AbdominalPalpation = {
    NonTender: 0,
    Tender: 1,
    Soft: 2,
    Hard: 3,
    LiverDistension: 4,
    // NonDistendedLiver: 5
}

export const AbdominalPalpationWithoutLiver = {
    NonTender: 0,
    Tender: 1,
    Soft: 2,
    Hard: 3,
}

export const BowelSounds = {
    Normoactive: 0,
    Hyperactive: 1,
    Hypoactive: 2,
    Absent: 3
}

export const BowelSoundFields = {
    RightUpperQuadrant: 0,
    LeftUpperQuadrant: 1,
    RightLowerQuadrant: 2,
    LeftLowerQuadrant: 3
}

export const RashTypes = {
    NoRash: 0,
    Sweaty: 1,
    Pale: 2,
    Hives: 3,
    Bruising: 4
}

export const WoundTypes = {
    NoWound: 0,
    Stage1: 1,
    Stage2: 2,
    Stage3: 3,
    Stage4: 4
}

export const WoundSites = {
    Head: 0,
    Shoulder: 1,
    Sacrum: 2,
    Buttock: 3,
    Heel: 4
}

export const SkinPalpations = {
    NormalSkin: 0,
    CoolSkin: 1,
    WarmSkin: 2,
    ClammySkin: 3,
    DrySkin: 4,
    DiaphoreticSkin: 5,
    TurgorDecreased: 6
}

// export const LabTests = {
//     Cbc: 0,
//     Cmp: 1,
//     Bnp: 2,
//     BloodCultures: 3,
//     Lactate: 4,
//     LipidPanel: 5,
//     Urinalysis: 6,
//     Lfts: 7,
//     Troponin: 8,
//     Potassium: 9,
//     Phosphorus: 10,
//     Magnesium: 11,
//     Calcium: 12,
//     Sodium: 13,
//     RenalPanel: 14,
//     Esr: 15,
//     Crp: 16,
//     PtInr: 17,
//     Aptt: 18,
//     SerumOsmolality: 19,
//     AnionGap: 20,
//     Procalcitonin: 21,
//     ThyroidFunctionPanel: 22,
//     BloodGlucosePoc: 23,
//     ArterialBloodGas: 24,
// };

export const LabTests = {
    PurpleTop: 0,
    GreenTop: 1,
    RedTop: 2,
    PinkTop: 3,
    BlueTop: 4,
    YellowTop: 5,
    GrayTop: 6,
};

export const MedicationTypes = {
    Epinephrine: 0,
    Amiodarone: 1,
    Dopamine: 2,
    NitroGlycerin: 3,
    Heparin: 4,
    // Streptokinase: 5,
    "0.9% Normal Saline": 6,
    Lisinopril: 7,
    Losartan: 8,
    Acetaminophen: 9,
    Labetalol: 10,
    Metoprolol: 11,
    Hydralazine: 12,
    Carvedilol: 13,
    Quetiapine: 14,
    Midazolam: 15,
    Digoxin: 16,
    Atropine: 17,
    Furosemide: 18,
    Tramadol: 19,
    Flumazenil: 20,
    Naloxone: 21,
    Amlodipine: 22,
    Midodrine: 23,
    FluVaccine: 24,
    RegularInsulin: 25,
    LactatedRingers: 26,
    DextroseInWater_5Percent: 27,
    DextroseInWater_10Percent: 28,
    Saline_3Percent: 29,
    Norepinephrine: 30,
    Nicardipine: 31,
    Dexmedetomidine: 32,
    Meropenem: 33,
    "Piperacillin/Tazobactam": 34,
    Vancomycin: 35,
    PotassiumChloride: 36,
    MagnesiumSulfate: 37,
    Dobutamine: 38,
    Vasopressin: 39,
    Phenylephrine: 40,
    Diltiazem: 41,
    Propofol: 42,
    Micafungin: 43,
    Pantoprazole: 44,
    Albumin: 45,
    Nitroprusside: 46,
    Lorazepam: 47,
    Morphine: 48,
    Vecuronium: 49,
    Fentanyl: 50,
    Nitroglycerine: 51,
    Captopril: 52,
    Ibuprofen: 53,
    Atorvastatin: 54,
    Metformin: 55,
    Methylprednisolone: 56,
    Loratadine: 57,
    Aspirin: 58,
    Warfarin: 59,
    Metronidazole: 60,
    Diphenhydramine: 61,
    Saline_045Percent: 62,
    Amiodarone_Bolus: 63,
    Amiodarone_Maintenance: 64,
    Ciprofloxacin: 65,
    InsulinLevemir: 66,
    DocusateSodium: 67,
    Ticagrelor: 68,
    Aripiprazole: 69,
    Haloperidol: 70,
    Lithium: 71,
    Sertraline: 72,
    Olanzapine: 73,
    Lactulose: 74,
    Oxytocin: 75,
    Methylergonovine: 76,
    Butorphanol: 77,
    Betamethasone: 78,
    Indomethacin: 79,
    Penicillin: 80,
    Alendronate: 81,
    Ceftriaxone: 82,
    Azithromycin: 83,
    Ivacaftor: 84,
    Prednisone: 85,
    Amantadine: 86,
    Amoxicillin: 87
};

export const MedicationRoute = {
    Oral: 0,
    IvPush: 1,
    Subcutaneous: 2,
    Intramuscular: 3
}

export const MedicationRouteInclIVBag = {
    Oral: 0,
    IvPush: 1,
    Subcutaneous: 2,
    Intramuscular: 3,
    IvBag: 4
}

export const MedicationUnit = {
    mg: 0,
    mgMl: 1,
    units: 2,
    dose: 3,
    ml: 4,
    mcgMl: 5,
    gMl: 6,
    meqMl: 7,
    unitsMl: 8,
    grams: 9
}

export const TitrationUnit = {
    mlH: 0,
    mcgMin: 1,
    mgHr: 2,
    mcgKgHr: 3,
    mcgKgMin: 4,
    unitsMin: 5,
    unitsHr: 6,
    mcgHr: 7
}

export const BehaviourTypes = {
    Normal: 0,
    Coughing: 1,
    ShortnessOfBreath: 2,
    Epiglottitis: 3,
    Panic: 4,
    Stridor: 5,
    ChestPain: 6,
    Drowsy: 7,
    LightSedation: 8,
    ModerateSedation: 9,
    DeepSedation: 10,
    CannotBeAroused: 11,
    Restless: 12,
    Agitated: 13,
    VeryAgitated: 14,
    Combative: 15,
    AbdominalPain: 16,
    LaborPain: 17,
    Delirium: 18
}

export const VitalTypes = {
    HeartRate: 0,
    SystolicPressure: 1,
    DiastolicPressure: 2,
    RespitoryRate: 3,
    OxygenSaturation: 4,
    Temperature: 5,
    BloodGlucose: 6,
    UrineOutput: 7
}

export const LungSoundFields = {
    "Posterior Left Superior Lobe": 0,
    "Posterior Left Upper Middle Lobe": 1,
    "Posterior Left Lower Middle Lobe": 2,
    "Posterior Left Inferior Lobe": 3,
    "Left Anterior Superior Lobe": 4,
    "Left Anterior Inferior Lobe": 5,
    "Posterior Right Superior Lobe": 6,
    "Posterior Right Upper Middle Lobe": 7,
    "Posterior Right Lower Middle Lobe": 8,
    "Posterior Right Inferior Lobe": 9,
    "Right Anterior Superior Lobe": 10,
    "Right Anterior Inferior Lobe": 11,
};

export const LungSounds = {
    Clear: 0,
    Wheezing: 1,
    Rhonchi: 2,
    CoarseCrackles : 3,
    FineCrackles: 4,
    PleuralRub: 5,
    Diminished: 6,
    Stridor: 7,
    Absent: 8
};

export const RespiratoryPattern = {
    Normal: 0,
    KussmaulRespirations: 1,
    CheyneStokes: 2
}

export const ChestShape = {
    Normal: 0,
    BarrelChest: 1,
    Scoliosis: 2,
    PectusCarinatum: 3,
    PectusExcavatum: 4,
    Kyphosis: 5
}

export const ChestExpansion = {
    Normal: 0,
    LeftDecreased: 1,
    RightDecreased: 2
}

export const Edema = {
    NoEdema: 0,
    PlusOne: 1,
    PlusTwo: 2,
    PlusThree: 3,
    PlusFour: 4
};

export const HeartSoundFields = {
    Aortic: 0,
    Pulmonic: 1,
    ErbsPoint: 2,
    Tricuspid: 3,
    Mitral: 4
}

export const HeartSounds = {
    S1S2: 0,
    S3: 1,
    S4: 2,
    HolosystolicMurmur: 3,
    MitralValveProlapse: 4,
    Absent: 5,
};

export const HeartRhythms = {
    NormalSinusRhythm: 0,
    SinusArrhythmia: 1,
    PrematureAtrialComplex: 2,
    AtrialFibrillation: 3,
    AtrialFlutter: 4,
    SupraventricularTachycardias: 5,
    JunctionalRhythm: 6,
    PrematureVentricularComplex: 7,
    VentricularTachycardia: 8,
    VentricularFibrillation: 9,
    IdioventricularRhythm: 10,
    Asystole: 11,
    FirstDegreeAvBlock: 12,
    SecondDegreeAvBlockType1: 13,
    SecondDegreeAvBlockType2: 14,
    ThirdDegreeAvBlock: 15,
    StElevation: 16
};

export const Pulses = {
    Strong: 0,
    Weak: 1,
    Absent: 2
};

export const SceneType = {
    Hospitalroom: 0,
    OutpatientClinic: 1
}

export const MetaHumans = {
    Ada: 0,
    Amelia: 1,
    Bes: 2,
    Dhruv: 3,
    Emory: 4,
    Glenda: 5,
    Irene: 6,
    Keiji: 7,
    Kendra: 8,
    Lucian: 9,
    Maria: 10,
    // Orla: 11,
    Oskar: 12,
    Pia: 13,
    Rowan: 14,
    Seneca: 15,
    SookJa: 16,
    Trey: 17,
    Valrie: 18,
    Wallace: 19
}

export const PulseFields = {
    ApicalImpulse: 0,
    CarotidArteryLeft: 1,
    BrachialArteryLeft: 2,
    RadialArteryLeft: 3,
    FemoralArteryLeft: 4,
    PoplitealArteryLeft: 5,
    PosteriorTibialArteryLeft: 6,
    DorsalisPedisArteryLeft: 7,
    CarotidArteryRight: 8,
    BrachialArteryRight: 9,
    RadialArteryRight: 10,
    FemoralArteryRight: 11,
    PoplitealArteryRight: 12,
    PosteriorTibialArteryRight: 13,
    DorsalisPedisArteryRight: 14
}

export const OrderTypes = {
    Nursing: 0,
    Medications: 1,
    Diet: 2,
    Lab: 3,
    Imaging: 4,
    Activity: 5
}

export const GradeCategory = {
    Safety: 0,
    Communication: 1,
    Accuracy: 2
}

export const OxygenType = {
    None: -1,
    NasalCannula: 0,
    SimpleFaceMask: 1,
    NonRebreather: 2,
}

export const InteractableType = {
    Stethoscope: 0,
    Penlight: 1,
    Clipboard: 2,
    LabKit: 3,
    Glucometer: 4,
    RespiratoryMasks: 5,
    IVPumps: 6
}

export const AACNDomains = {
    "Knowledge for Nursing Practice": 0,
    "Person-Centered Care": 1,
    "Population Health": 2,
    "Scholarship for Nursing Discipline": 3,
    "Quality and Safety": 4,
    "Interprofessional Partnerships": 5,
    "Systems-Based Practice": 6,
    "Informatics and Healthcare Technologies": 7,
    "Professionalism": 8,
    "Personal, Professional, and Leadership Development": 9,
};

export const AACNCoreCompetencies = {
    0: {
        "Demonstrate an understanding of the discipline of nursing’s distinct perspective and where shared perspectives exist with other disciplines": 0,
        "Apply theory and research-based knowledge from nursing, the arts, humanities, and other sciences": 1,
        "Demonstrate clinical judgment founded on a broad knowledge base": 2
    },
    1: {
        "Engage with the individual in establishing a caring relationship": 0,
        "Communicate effectively with individuals": 1,
        "Integrate assessment skills in practice": 2,
        "Diagnose actual or potential health problems and needs": 3,
        "Develop a plan of care": 4,
        "Demonstrate accountability for care delivery": 5,
        "Evaluate outcomes of care": 6,
        "Promote self-care management": 7,
        "Provide care coordination": 8
    },
    2: {
        "Manage population health": 0,
        "Engage in effective partnerships": 1,
        "Consider the socioeconomic impact of the delivery of health care": 2,
        "Advance equitable population health policy": 3,
        "Demonstrate advocacy strategies": 4,
        "Advance preparedness to protect population health during disasters and public health emergencies": 5
    },
    3: {
        "Advance the scholarship of nursing": 0,
        "Integrate best evidence into nursing practice": 1,
        "Promote the ethical conduct of scholarly activities": 2
    },
    4: {
        "Apply quality improvement principles in care delivery": 0,
        "Contribute to a culture of patient safety": 1,
        "Contribute to a culture of provider and work environment safety": 2
    },
    5: {
        "Communicate in a manner that facilitates a partnership approach to quality care delivery": 0,
        "Perform effectively in different team roles, using principles and values of team dynamics": 1,
        "Use knowledge of nursing and other professions to address healthcare needs": 2,
        "Work with other professions to maintain a climate of mutual learning, respect, and shared values": 3
    },
    6: {
        "Apply knowledge of systems to work effectively across the continuum of care": 0,
        "Incorporate consideration of cost-effectiveness of care": 1,
        "Optimize system effectiveness through application of innovation and evidence-based practice": 2
    },
    7: {
        "Describe the various information and communication technology tools used in the care of patients, communities, and populations": 0,
        "Use information and communication technology to gather data, create information, and generate knowledge": 1,
        "Use information and communication technologies and informatics processes to deliver safe nursing care to diverse populations in a variety of settings": 2,
        "Use information and communication technology to support documentation of care and communication among providers, patients, and all system levels": 3,
        "Use information and communication technologies in accordance with ethical, legal, professional, and regulatory standards, and workplace policies in the delivery of care": 4
    },
    8: {
        "Demonstrate an ethical comportment in one’s practice reflective of nursing’s mission to society": 0,
        "Employ participatory approach to nursing care": 1,
        "Demonstrate accountability to the individual, society, and the profession": 2,
        "Comply with relevant laws, policies, and regulations": 3,
        "Demonstrate the professional identity of nursing": 4,
        "Integrate diversity, equity, and inclusion as core to one’s professional identity": 5
    },
    9: {
        "Demonstrate a commitment to personal health and well-being": 0,
        "Demonstrate a spirit of inquiry that fosters flexibility and professional maturity": 1,
        "Develop capacity for leadership": 2
    }
};


export const AACNSubCompetencies = {
    //AACN Domain 1 
    0: {
        //AACN Subdomain 1.1 
        0: {
            "Identify concepts, derived from theories from nursing and other disciplines,   which distinguish the practice of nursing.": 0,
            "Apply knowledge of nursing science that develops a foundation for nursing practice.": 1,
            "Understand the historical foundation of nursing as the relationship developed between the individual and nurse.": 2,
            "Articulate nursing’s distinct perspective to practice.": 3,
            "Translate evidence from nursing science as well as other sciences into practice.": 4,
            "Demonstrate the application of nursing science to practice.": 5,
            "Integrate an understanding of nursing history in advancing nursing’s influence in health care.": 6,
        },
        //AACN Subdomain 1.2 
        1: {
            "Apply or employ knowledge from nursing science as well as the natural, physical, and social sciences to build an understanding of the human experience and nursing practice.": 0,
            "Demonstrate intellectual curiosity.": 1,
            "Demonstrate social responsibility as a global citizen who fosters the attainment of health equity for all.": 2,
            "Examine the influence of personal values in decision making for nursing practice.": 3,
            "Demonstrate ethical decision making.": 4,
            "Synthesize knowledge from nursing and other disciplines to inform education, practice, and research.": 5,
            "Apply a systematic and defendable approach to nursing practice decisions.": 6,
            "Employ ethical decision making to assess, intervene, and evaluate nursing care.": 7,
            "Demonstrate socially responsible leadership.": 8,
            "Translate theories from nursing and other disciplines to practice.": 9
        },
        //AACN Subdomain 1.3 
        2: {
            "Demonstrate clinical reasoning.": 0,
            "Integrate nursing knowledge (theories, multiple ways of knowing, evidence) and knowledge from other disciplines and inquiry to inform clinical judgment.": 1,
            "Incorporate knowledge from nursing and other disciplines to support clinical judgment.": 2,
            "Integrate foundational and advanced specialty knowledge into clinical reasoning.": 3,
            "Synthesize current and emerging evidence to Influence practice.": 4,
            "Analyze decision models from nursing and other knowledge domains to improve clinical judgment.": 5
        }
    },
    //AACN Domain 2 
    1: {
        //2.1 
        0: {
            "Demonstrate qualities of empathy.": 0,
            "Demonstrate compassionate care.": 1,
            "Establish mutual respect with the individual and family.": 2,
            "Promote caring relationships to effect positive outcomes.": 3,
            "Foster caring relationships.": 4,
        },
        //2.2 
        1: {
            "Demonstrate relationship-centered care.": 0,
            "Consider individual beliefs, values, and personalized information in communications.": 1,
            "Use a variety of communication modes appropriate for the context.": 2,
            "Demonstrate the ability to conduct sensitive or difficult conversations.": 3,
            "Use evidence-based patient teaching materials, considering health literacy, vision, hearing, and cultural sensitivity.": 4,
            "Demonstrate emotional intelligence in communications.": 5,
            "Apply individualized information, such as genetic/genomic, pharmacogenetic, and environmental exposure information in the delivery of personalized health care.": 6,
            "Facilitate difficult conversations and disclosure of sensitive information.": 7
        },
        //2.3 
        2: {
            "Create an environment during assessment that promotes a dynamic interactive experience.": 0,
            "Obtain a complete and accurate history in a systematic manner.": 1,
            "Perform a clinically relevant, holistic health assessment.": 2,
            "Perform point of care screening/diagnostic testing (e.g. blood glucose, PO2, EKG).": 3,
            "Distinguish between normal and abnormal health findings.": 4,
            "Apply nursing knowledge to gain a holistic perspective of the person, family, community, and population.": 5,
            "Communicate findings of a comprehensive assessment.": 6,
            "Demonstrate that one’s practice is informed by a comprehensive assessment appropriate to the functional area of advanced nursing practice.": 7
        },
        //2.4 
        3: {
            "Synthesize assessment data in the context of the individual’s current preferences, situation, and experience.": 0,
            "Create a list of problems/health concerns.": 1,
            "Prioritize problems/health concerns.": 2,
            "Understand and apply the results of social screening, psychological testing, laboratory data, imaging studies, and other diagnostic tests in actions and plans of care.": 3,
            "Contribute as a team member to the formation and improvement of diagnoses.": 4,
            "Employ context driven, advanced reasoning to the diagnostic and decision-making process.": 5,
            "Integrate advanced scientific knowledge to guide decision making.": 6
        },
        //2.5 
        4: {
            "Engage the individual and the team in plan development.": 0,
            "Organize care based on mutual health goals.": 1,
            "Prioritize care based on best evidence.": 2,
            "Incorporate evidence-based intervention to improve outcomes and safety.": 3,
            "Anticipate outcomes of care (expected, unexpected, and potentially adverse).": 4,
            "Demonstrate rationale for plan.": 5,
            "Address individuals’ experiences and perspectives in designing plans of care.": 6,
            "Lead and collaborate with an interprofessional team to develop a comprehensive plan of care.": 7,
            "Prioritize risk mitigation strategies to prevent or reduce adverse outcomes.": 8,
            "Develop evidence-based interventions to improve outcomes and safety.": 9,
            "Incorporate innovations into practice when evidence is not available.": 10
        },
        //2.6 
        5: {
            "Implement individualized plan of care using established protocols.": 0,
            "Communicate care delivery through multiple modalities.": 1,
            "Delegate appropriately to team members.": 2,
            "Monitor the implementation of the plan of care.": 3,
            "Model best care practices to the team.": 4,
            "Monitor aggregate metrics to assure accountability for care outcomes.": 5,
            "Promote delivery of care that supports practice at the full scope of education.": 6,
            "Contribute to the development of policies and processes that promote transparency and accountability.": 7,
            "Apply current and emerging evidence to the development of care guidelines/tools.": 8,
            "Ensure accountability throughout transitions of care across the health continuum.": 9
        },
        //2.7 
        6: {
            "Reassess the individual to evaluate health outcomes/goals.": 0,
            "Modify plan of care as needed.": 1,
            "Recognize the need for modifications to standard practice.": 2,
            "Analyze data to identify gaps and inequities in care and monitor trends in outcomes.": 3,
            "Monitor epidemiological and system-level aggregate data to determine healthcare outcomes and trends.": 4,
            "Synthesize outcome data to inform evidencebased practice, guidelines, and policies.": 5
        },
        //2.8 
        7: {
            "Assist the individual to engage in self-care management.": 0,
            "Employ individualized educational strategies based on learning theories, methodologies, and health literacy.": 1,
            "Educate individuals and families regarding selfcare for health promotion, illness prevention, and illness management.": 2,
            "Respect individuals and families’ selfdetermination in their healthcare decisions.": 3,
            "Identify personal, system, and community resources available to support self-care management.": 4,
            "Develop strategies that promote self-care management.": 5,
            "Incorporate the use of current and emerging technologies to support self-care management.": 6,
            "Employ counseling techniques, including motivational interviewing, to advance wellness and self-care management.": 7,
            "Evaluate adequacy of resources available to support self-care management.": 8,
            "Foster partnerships with community organizations to support self-care management.": 9
        },
        //2.9 
        8: {
            "Facilitate continuity of care based on assessment of assets and needs.": 0,
            "Communicate with relevant stakeholders across health systems.": 1,
            "Promote collaboration by clarifying responsibilities among individual, family, and team members.": 2,
            "Recognize when additional expertise and knowledge is needed to manage the patient.": 3,
            "Provide coordination of care of individuals and families in collaboration with care team.": 4,
            "Evaluate communication pathways among providers and others across settings, systems, and communities.": 5,
            "Develop strategies to optimize care coordination and transitions of care.": 6,
            "Guide the coordination of care across health systems.": 7,
            "Analyze system-level and public policy influence on care coordination.": 8,
            "Participate in system-level change to improve care coordination across settings.": 9
        },
    },
    //AACN Domain 3 
    2: {
        //3.1 
        0: {
            "Define a target population including its functional and problem-solving capabilities (anywhere in the continuum of care).": 0,
            "Assess population health data.": 1,
            "Assess the priorities of the community and/or the affected clinical population.": 2,
            "Compare and contrast local, regional, national, and global benchmarks to identify health patterns across populations.": 3,
            "Apply an understanding of the public health system and its interfaces with clinical health care in addressing population health needs.": 4,
            "Develop an action plan to meet an identified need(s), including evaluation methods.": 5,
            "Participate in the implementation of sociocultural and linguistically responsive interventions.": 6,
            "Describe general principles and practices for the clinical management of populations across the age continuum.": 7,
            "Identify ethical principles to protect the health and safety of diverse populations.": 8,
            "Assess the efficacy of a system’s capability to serve a target sub-population’s healthcare needs.": 9,
            "Analyze primary and secondary population health data for multiple populations against relevant benchmarks.": 10,
            "Use established or evolving methods to determine population-focused priorities for care.": 11,
            "Develop a collaborative approach with relevant stakeholders to address population healthcare needs, including evaluation methods.": 12,
            "Collaborate with appropriate stakeholders to implement a sociocultural and linguistically responsive intervention plan.": 13
        },
        //3.2 
        1: {
            "Engage with other health professionals to address population health issues.": 0,
            "Demonstrate effective collaboration and mutual accountability with relevant stakeholders.": 1,
            "Use culturally and linguistically responsive communication strategies.": 2,
            " Ascertain collaborative opportunities for individuals and organizations to improve population health.": 3,
            " Challenge biases and barriers that impact population health outcomes.": 4,
            "Evaluate the effectiveness of partnerships for achieving health equity.": 5,
            "Lead partnerships to improve population health outcomes.": 6,
            "Assess preparation and readiness of partners to organize during natural and manmade disasters.": 7
        },
        //3.3 
        2: {
            "Describe access and equity implications of proposed intervention(s).": 0,
            "Prioritize patient-focused and/or community action plans that are safe, effective, and efficient in the context of available resources.": 1,
            "Analyze cost-benefits of selected population based interventions.": 2,
            "Collaborate with partners to secure and leverage resources necessary for effective, sustainable interventions.": 3,
            "Advocate for interventions that maximize cost effective, accessible, and equitable resources for populations.": 4,
            "Incorporate ethical principles in resource allocation in achieving equitable health.": 5
        },
        //3.4 
        3: {
            "Describe policy development processes.": 0,
            "Describe the impact of policies on population outcomes, including social justice and health equity.": 1,
            "Identify best evidence to support policy development.": 2,
            "Propose modifications to or development of policy based on population findings.": 3,
            "Develop an awareness of the interconnectedness of population health across borders.": 4,
            "Identify opportunities to influence the policy process.": 5,
            "Design comprehensive advocacy strategies to support the policy process.": 6,
            "Engage in strategies to influence policy change.": 7,
            "Contribute to policy development at the system, local, regional, or national levels.": 8,
            "Assess the impact of policy changes.": 9,
            "Evaluate the ability of policy to address disparities and inequities within segments of the population.": 10,
            "Evaluate the risks to population health associated with globalization.": 11
        },
        //3.5 
        4: {
            "Articulate a need for change.": 0,
            "Describe the intent of the proposed change.": 1,
            "Define stakeholders, including members of the community and/or clinical            populations, and their level of influence.": 2,
            "Implement messaging strategies appropriate to audience and stakeholders.": 3,
            "Evaluate the effectiveness of advocacy actions.": 4,
            "Appraise advocacy priorities for a population.": 5,
            "Strategize with an interdisciplinary group and others to develop effective advocacy approaches.": 6,
            "Engage in relationship-building activities with stakeholders at any level of              influence, including system, local, state, national, and/or global.": 7,
            "Demonstrate leadership skills to promote advocacy efforts that include principles of social justice, diversity, equity, and inclusion.": 8
        },
        //3.6 
        5: {
            "Identify changes in conditions that might indicate a disaster or public health emergency.": 0,
            "Understand the impact of climate change on environmental and population health.": 1,
            "Describe the health and safety hazards of disasters and public health emergencies.": 2,
            "Describe the overarching principles and methods regarding personal safety measures, including personal protective equipment (PPE).": 3,
            "Implement infection control measures and proper use of personal protective equipment.": 4,
            "Collaboratively initiate rapid response activities to protect population health.": 5,
            "Participate in ethical decision making that includes diversity, equity, and inclusion in advanced preparedness to protect populations.": 6,
            "Collaborate with interdisciplinary teams to lead preparedness and mitigation efforts to protect population health with attention to the most vulnerable populations.": 7,
            "Coordinate the implementation of evidence based infection control measures and proper use of personal protective equipment.": 8,
            "Contribute to system-level planning, decision making, and evaluation for disasters and public health emergencies.": 9
        },
    },
    //AACN Domain 4 
    3: {
        //4.1 
        0: {
            "Demonstrate an understanding of different approaches to scholarly practice.": 0,
            "Demonstrate application of different levels of evidence.": 1,
            "Apply theoretical framework(s)/models in practice.": 2,
            "Demonstrate an understanding of basic elements of the research process.": 3,
            "Participate in scholarly inquiry as a team member.": 4,
            "Evaluate research.": 5,
            "Communicate scholarly findings.": 6,
            "Apply and critically evaluate advanced knowledge in a defined area of nursing practice.": 7,
            "Engage in scholarship to advance health.": 8,
            "Discern appropriate applications of quality improvement, research, and evaluation methodologies.": 9,
            "Collaborate to advance one’s scholarship.": 10,
            "Disseminate one’s scholarship to diverse audiences using a variety of                       approaches or modalities.": 11,
            "Advocate within the interprofessional team and with other stakeholders for the contributions of nursing scholarship.": 12
        },
        //4.2 
        1: {
            "Evaluate clinical practice to generate questions to improve nursing care.": 0,
            "Evaluate appropriateness and strength of the evidence.": 1,
            "Use best evidence in practice.": 2,
            "Participate in the implementation of a practice change to improve nursing care.": 3,
            "Participate in the evaluation of outcomes and their implications for practice.": 4,
            "Use diverse sources of evidence to inform practice.": 5,
            "Lead the translation of evidence into practice.": 6,
            "Address opportunities for innovation and changes in practice.": 7,
            "Collaborate in the development of new/revised policy or regulation in the light of new evidence.": 8,
            "Articulate inconsistencies between practice policies and best evidence.": 9,
            "Evaluate outcomes and impact of new practices based on the evidence.": 10
        },
        //4.3 
        2: {
            "Explain the rationale for ethical research guidelines, including Institutional Review Board (IRB) guidelines.": 0,
            "Demonstrate ethical behaviors in scholarly projects including quality improvement and EBP initiatives.": 1,
            "Advocate for the protection of participants in the conduct of scholarly initiatives.": 2,
            "Recognize the impact of equity issues in research.": 3,
            "Identify and mitigate potential risks and area of ethical concern in the conduct of scholarly activities.": 4,
            "Apply IRB guidelines throughout the scholarship process.": 5,
            "Ensure the protection of participants in the conduct of scholarship.": 6,
            "Implement processes that support ethical conduct in practice and  scholarship.": 7,
            "Apply ethical principles to the dissemination of nursing scholarship.": 8
        },
    },
    //AACN Domain 5 
    4: {
        //5.1 
        0: {
            "Recognize nursing’s essential role in improving healthcare quality and safety.": 0,
            "Identify sources and applications of national safety and quality standards to guide nursing practice.": 1,
            "Implement standardized, evidence-based processes for care delivery.": 2,
            "Interpret benchmark and unit outcome data to inform individual and microsystem practice.": 3,
            "Compare quality improvement methods in the delivery of patient care.": 4,
            "Identify strategies to improve outcomes of patient care in practice.": 5,
            "Participate in the implementation of a practice change.": 6,
            "Develop a plan for monitoring quality improvement change.": 7,
            "Establish and incorporate data driven benchmarks to monitor system performance.": 8,
            "Use national safety resources to lead team-based change initiatives.": 9,
            "Integrate outcome metrics to inform change and policy recommendations.": 10,
            "Collaborate in analyzing organizational process improvement initiatives.": 11,
            "Lead the development of a business plan for quality improvement initiatives.": 12,
            "Advocate for change related to financial policies that impact the relationship between economics and quality care delivery.": 13,
            "Advance quality improvement practices through dissemination of outcomes.": 14
        },
        //5.2 
        1: {
            "Describe the factors that create a culture of safety.": 0,
            "Articulate the nurse’s role within an interprofessional team in promoting safety and preventing errors and near misses.": 1,
            "Examine basic safety design principles to reduce risk of harm.": 2,
            "Assume accountability for reporting unsafe conditions, near misses, and errors to reduce harm.": 3,
            "Describe processes used in understanding causes of error.": 4,
            "Use national patient safety resources, initiatives, and regulations at the point of care.": 5,
            "Evaluate the alignment of system data and comparative patient safety benchmarks.": 6,
            "Lead analysis of actual errors, near misses, and potential situations that would impact safety.": 7,
            "Design evidence-based interventions to mitigate risk.": 8,
            "Evaluate emergency preparedness system-level plans to protect safety.": 9
        },
        //5.3 
        2: {
            "Identify actual and potential level of risks to providers within the workplace.": 0,
            "Recognize how to prevent workplace violence and injury.": 1,
            "Promote policies for prevention of violence and risk mitigation.": 2,
            "Recognize one’s role in sustaining a just culture reflecting civility and respect.": 3,
            "Advocate for structures, policies, and processes that promote a culture of safety and prevent workplace risks and injury.": 4,
            "Foster a just culture reflecting civility and respect.": 5,
            "Create a safe and transparent culture for reporting incidents.": 6,
            "Role model and lead well-being and resiliency for self and team.": 7
        },
    },
    //AACN Domain 6 
    5: {
        //6.1 
        0: {
            "Communicate the nurse’s roles and responsibilities clearly.": 0,
            "Use various communication tools and techniques effectively.": 1,
            "Elicit the perspectives of team members to inform person-centered care decision making.": 2,
            "Articulate impact of diversity, equity, and inclusion on team-based communications.": 3,
            "Communicate individual information in a professional, accurate, and timely manner.": 4,
            "Communicate as informed by legal, regulatory, and policy guidelines.": 5,
            "Evaluate effectiveness of interprofessional communication tools and techniques to support and improve the efficacy of team-based interactions.": 6,
            "Facilitate improvements in interprofessional communications of individual information (e.g. EHR).": 7,
            "Role model respect for diversity, equity, and inclusion in team-based communications.": 8,
            "Communicate nursing’s unique disciplinary knowledge to strengthen interprofessional partnerships.": 9,
            "Provide expert consultation for other members of the healthcare team in one’s area of practice.": 10,
            "Demonstrate capacity to resolve interprofessional conflict.": 11
        },
        //6.2 
        1: {
            "Apply principles of team dynamics, including team roles, to facilitate effective team functioning.": 0,
            "Delegate work to team members based on their roles and competency.": 1,
            "Engage in the work of the team as appropriate to one’s scope of practice and competency.": 2,
            "Recognize how one’s uniqueness (as a person and a nurse) contributes to effective interprofessional working relationships.": 3,
            "Apply principles of team leadership and management. performance to improve quality and assure safety.": 4,
            "Evaluate performance of individual and team to improve quality and promote safety.": 5,
            "Integrate evidence-based strategies and processes to improve team effectiveness and outcomes.": 6,
            "Evaluate the impact of team dynamics and performance on desired outcomes.": 7,
            "Reflect on how one’s role and expertise influences team performance.": 8,
            "Foster positive team dynamics to strengthen desired outcomes.": 9
        },
        //6.3 
        2: {
            "Integrate the roles and responsibilities of healthcare professionals through interprofessional collaborative practice.": 0,
            "Leverage roles and abilities of team members to optimize care.": 1,
            "Communicate with team members to clarify responsibilities in executing plan   of care.": 2,
            "Direct interprofessional activities and initiatives.": 3,

        },
        //6.4 
        3: {
            "Demonstrate an awareness of one’s biases and how they may affect mutual respect and communication with team members.": 0,
            "Demonstrate respect for the perspectives and experiences of other professions.": 1,
            "Engage in constructive communication to facilitate conflict management.": 2,
            "Collaborate with interprofessional team members to establish mutual healthcare goals for individuals, communities, or populations.": 3,
            "Practice self-assessment to mitigate conscious and implicit biases toward other team members.": 4,
            "Foster an environment that supports the constructive sharing of multiple perspectives and enhances interprofessional learning.": 5,
            "Integrate diversity, equity, and inclusion into team practices.": 6,
        },
    },
    //AACN Domain 7 
    6: {
        //7.1 
        0: {
            "Describe organizational structure, mission, vision, philosophy, and values.": 0,
            "Explain the relationships of macrosystems, mesosystems, and microsystems.": 1,
            "Differentiate between various healthcare delivery environments across the continuum of care.": 2,
            "Recognize internal and external system processes that impact care coordination and transition of care.": 3,
            "Participate in organizational strategic planning.": 4,
            "Participate in system-wide initiatives that improve care delivery and/or outcomes.": 5,
            "Analyze system-wide processes to optimize outcomes.": 6,
            "Design policies to impact health equity and structural racism within systems,    communities, and populations.": 7
        },
        //7.2 
        1: {
            "Describe the financial and payment models of health care.": 0,
            "Recognize the impact of health disparities and social determinants of health on care outcomes.": 1,
            "Describe the impact of healthcare cost and payment models on the delivery,  access, and quality of care.": 2,
            "Explain the relationship of policy, regulatory requirements, and economics on care outcomes.": 3,
            "Incorporate considerations of efficiency, value, and cost in providing care.": 4,
            "Identify the impact of differing system structures, leadership, and workforce needs on care outcomes.": 5,
            "Analyze relevant internal and external factors that drive healthcare costs and reimbursement": 6,
            "Design practices that enhance value, access, quality, and cost-effectiveness.": 7,
            "Advocate for healthcare economic policies and regulations to enhance value, quality, and cost effectiveness.": 8,
            "Formulate, document, and disseminate the return on investment for improvement initiatives collaboratively with an interdisciplinary team.": 9,
            "Recommend system-wide strategies that improve cost-effectiveness considering structure, leadership, and workforce needs.": 10,
            "Evaluate health policies based on an ethical framework considering cost-effectiveness, health equity, and care outcomes.": 11

        },
        //7.3 
        2: {
            "Demonstrate a systematic approach for decision-making.": 0,
            "Use reported performance metrics to compare/monitor outcomes": 1,
            "Participate in evaluating system effectiveness.": 2,
            "Recognize internal and external system processes and structures that perpetuate racism and other forms of discrimination within health care.": 3,
            "Apply innovative and evidence-based strategies focusing on system preparedness and capabilities.": 4,
            "Design system improvement strategies based on performance data and metrics.": 5,
            "Manage change to sustain system effectiveness.": 6,
            "Design system improvement strategies that address internal and external system processes and structures that perpetuate structural racism and other forms of discrimination in healthcare systems.": 7

        },
    },
    //AACN Domain 8 
    7: {
        //8.1 
        0: {
            "Identify the variety of information and communication technologies used in care settings.": 0,
            "Identify the basic concepts of electronic health, mobile health, and telehealth systems for enabling patient care.": 1,
            "Effectively use electronic communication tools.": 2,
            "Describe the appropriate use of multimedia applications in health care.": 3,
            "Demonstrate best practice use of social networking applications.": 4,
            "Explain the importance of nursing engagement in the planning and selection of healthcare technologies.": 5,
            "Identify best evidence and practices for the application of information and  communication technologies to support care.": 6,
            "Evaluate the unintended consequences of information and communication technologies on care processes, communications, and information flow across care settings.": 7,
            "Propose a plan to influence the selection and implementation of new information and communication technologies.": 8,
            "Explore the fiscal impact of information and communication technologies on health care.": 9,
            "Identify the impact of information and communication technologies on workflow processes and healthcare outcomes.": 10

        },
        //8.2 
        1: {
            "Enter accurate data when chronicling care.": 0,
            "Explain how data entered on one patient impacts public and population health data.": 1,
            "Use appropriate data when planning care.": 2,
            "Demonstrate the appropriate use of health information literacy assessments and improvement strategies.": 3,
            "Describe the importance of standardized nursing data to reflect the unique contribution of nursing practice.": 4,
            "Generate information and knowledge from health information technology databases.": 5,
            "Evaluate the use of communication technology to improve consumer health information literacy.": 6,
            "Use standardized data to evaluate decision making and outcomes across all systems levels.": 7,
            "Clarify how the collection of standardized data advances the practice, understanding, and value of nursing and supports care.": 8,
            "Interpret primary and secondary data and other information to support care.": 9
        },
        //8.3 
        2: {
            "Demonstrate appropriate use of information and communication technologies.": 0,
            "Evaluate how decision support tools impact clinical judgment and safe patient care.": 1,
            "Use information and communication technology in a manner that supports the nurse-patient relationship.": 2,
            "Examine how emerging technologies influence healthcare delivery and clinical decision making.": 3,
            "Identify impact of information and communication technology on quality and safety of care.": 4,
            "Identify the importance of reporting system processes and functional issues (error messages, mis-directions, device malfunctions, etc.) according to organizational policies and procedures.": 5,
            "Evaluate the use of information and communication technology to address needs, gaps, and inefficiencies in care.": 6,
            "Formulate a plan to influence decision making processes for selecting, implementing, and evaluating support tools.": 7,
            "Appraise the role of information and communication technologies in engaging the patient and supporting the nurse-patient relationship.": 8,
            "Evaluate the potential uses and impact of emerging technologies in health care.": 9,
            "Pose strategies to reduce inequities in digital access to data and information.": 10

        },
        //8.4 
        3: {
            "Explain the role of communication technology in enhancing clinical information flows.": 0,
            "Describe how information and communication technology tools support patient and team communications.": 1,
            "Identify the basic concepts of electronic health, mobile health, and telehealth systems in enabling patient care.": 2,
            "Explain the impact of health information exchange, interoperability, and integration on health care.": 3,
            "Assess best practices for the use of advanced information and communication technologies to support patient and team communications.": 4,
            "Employ electronic health, mobile health, and telehealth systems to enable quality, ethical, and efficient patient care.": 5,
            "Evaluate the impact of health information exchange, interoperability, and integration to support patient-centered care.": 6
        },
        //8.5 
        4: {
            "Identify common risks associated with using information and communication technology.": 0,
            "Demonstrate ethical use of social networking applications.": 1,
            "Comply with legal and regulatory requirements while using communication and information technologies.": 2,
            "Educate patients on their rights to access, review, and correct personal data and medical records.": 3,
            "Discuss how clinical judgment and critical thinking must prevail in the presence of information and communication technologies.": 4,
            "Deliver care using remote technology.": 5,
            "Apply risk mitigation and security strategies to reduce misuse of information and communication technology.": 6,
            "Assess potential ethical and legal issues associated with the use of information and communication technology.": 7,
            "Recommend strategies to protect health information when using communication and information technology.": 8,
            "Promote patient engagement with their personal health data.": 9,
            "Advocate for policies and regulations that support the appropriate use of technologies impacting health care.": 10,
            "Analyze the impact of federal and state policies and regulation on health data and technology in care settings.": 11

        },
    },
    //AACN Domain 9 
    8: {
        //9.1 
        0: {
            "Apply principles of professional nursing ethics and human rights in patient care and professional situations.": 0,
            "Reflect on one’s actions and their consequences.": 1,
            "Demonstrate ethical behaviors in practice.": 2,
            "Change behavior based on self and situational awareness.": 3,
            "Report unethical behaviors when observed.": 4,
            "Safeguard privacy, confidentiality, and autonomy in all interactions.": 5,
            "Advocate for the individual’s right to self determination": 6,
            "Analyze current policies and practices in the context of an ethical framework.": 7,
            "Model ethical behaviors in practice and leadership roles.": 8,
            "Suggest solutions when unethical behaviors are observed.": 9,
            "Assume accountability for working to resolve ethical dilemmas.": 10
        },
        //9.2 
        1: {
            "Employ the use of intentional presence to facilitate shared meaning of the experience between nurse and recipient of care.": 0,
            "Facilitate health and healing through compassionate caring.": 1,
            "Demonstrate empathy to the individual’s life experience.": 2,
            "Advocate for practices that advance diversity, equity, and inclusion.": 3,
            "Demonstrate cultural sensitivity and humility in practice.": 4,
            "Apply principles of therapeutic relationships and professional boundaries.": 5,
            "Communicate in a professional manner.": 6,
            "Foster opportunities for intentional presence in practice.": 7,
            "Identify innovative and evidence-based practices that promote person-centered care.": 8,
            "Advocate for practices that advance diversity, equity, and inclusion.": 9,
            "Model professional expectations for therapeutic relationships.": 10,
            "Facilitate communication that promotes a participatory approach.": 11
        },
        //9.3 
        2: {
            "Engage in advocacy that promotes the best interest of the individual, community, and profession.": 0,
            "Demonstrate the moral courage to report concerns related to actual or potential hazards and/or errors.": 1,
            "Demonstrate professional and personal honesty and integrity.": 2,
            "Take responsibility for one’s roles, decisions, obligations, actions, and care outcomes.": 3,
            "Engage in professional activities and/or organizations.": 4,
            "Demonstrate adherence to a culture of civility.": 5,
            "Advocate for social justice and health equity, including addressing the health of vulnerable populations.": 6,
            "Engage in peer evaluation.": 7,
            "Advocate for nursing’s professional responsibility for ensuring optimal care outcomes.": 8,
            "Demonstrate leadership skills when participating in professional activities and/or organizations.": 9,
            "Address actual or potential hazards and/or errors.": 10,
            "Foster a practice environment that promotes accountability for care outcomes.": 11,
            "Advocate for policies/practices that promote social justice and health equity.": 12,
            "Foster strategies that    promote a culture of civility across a variety of settings.": 13,
            "Lead in the development of opportunities for professional and interprofessional activities.": 14
        },
        //9.4 
        3: {
            "Advocate for policies that promote health and prevent harm.": 0,
            "Adhere to the registered nurse scope and standards of practice.": 1,
            "Adhere to regulatory requirements and workplace policies consistent with  one’s educational preparation.": 2,
            "Advocate for policies that enable nurses to practice to the full extent of their education.": 3,
            "Assess the interaction between regulatory agency requirements and quality, fiscal, and value-based indicators.": 4,
            "Evaluate the effect of legal and regulatory policies on nursing practice and healthcare outcomes.": 5,
            "Analyze efforts to change legal and regulatory policies that improve nursing practice and health outcomes.": 6,
            "Participate in the implementation of policies and regulations to improve the professional practice environment and healthcare outcomes.": 7

        },
        //9.5 
        4: {
            "Describe nursing’s professional identity and contributions to the healthcare  team.": 0,
            "Demonstrate the core values of professional nursing identity.": 1,
            "Demonstrate sensitivity to the values of others.": 2,
            "Demonstrate ethical comportment and moral courage in decision making and actions.": 3,
            "Demonstrate emotional intelligence.": 4,
            "Articulate nursing’s unique professional identity to other interprofessional team members and the public.": 5,
            "Evaluate practice environment to ensure that nursing core values are demonstrated.": 6,
            "Identify opportunities to lead with moral courage to influence team decision-making.": 7,
            "Engage in professional organizations that reflect nursing’s values and identity.": 8

        },
        //9.6 
        5: {
            "Demonstrate respect for diverse individual differences and diverse communities and populations": 0,
            "Demonstrate awareness of personal and professional values and conscious and unconscious biases.": 1,
            "Integrate core principles of social justice and human rights into practice.": 2,
            "Model respect for diversity, equity, and inclusion for all team members.": 3,
            "Critique one’s personal and professional practices in the context of nursing’s core values.": 4,
            "Analyze the impact of structural and cultural influences on nursing’s professional identity.": 5,
            "Ensure that care provided by self and others is reflective of nursing’s core values.": 6,
            "Structure the practice environment to facilitate care that is culturally and linguistically appropriate.": 7,
            "Ensure self and others are accountable in upholding moral, legal, and humanistic principles related to health.": 8
        },
    },
    //AACN Domain 10 
    9: {
        //10.1 
        0: {
            "Demonstrate healthy, self-care behaviors that promote wellness and resiliency.": 0,
            "Manage conflict between personal and professional responsibilities.": 1,
            "Contribute to an environment that promotes self-care, personal health, and well-being.": 2,
            "Evaluate the workplace environment to determine level of health and well-being.": 3
        },
        //10.2 
        1: {
            "Engage in guided and spontaneous reflection of one’s practice.": 0,
            "Integrate comprehensive feedback to improve performance.": 1,
            "Commit to personal and professional development.": 2,
            "Expand personal knowledge to inform clinical judgment.": 3,
            "Identify role models and mentors to support professional growth.": 4,
            "Participate in ongoing activities that embrace of diversity, equity, inclusion, and antidiscrimination.": 5,
            "Demonstrate cognitive flexibility in managing change within complex environments.": 6,
            "Mentor others in the development of their professional growth and accountability.": 7,
            "Foster activities that support a culture of lifelong learning.": 8,
            "Expand leadership skills through professional service.": 9
        },
        //10.3 
        2: {
            "Compare and contrast leadership principles and theories.": 0,
            "Formulate a personal leadership style.": 1,
            "Demonstrate leadership behaviors in professional situations.": 2,
            "Demonstrate self-efficacy consistent with one’s professional development.": 3,
            "Use appropriate resources when dealing with ambiguity.": 4,
            "Modify one’s own leadership behaviors based on guided self-reflection.": 5,
            "Demonstrate self-awareness of one’s own implicit biases and their relationship to one’s culture and environment.": 6,
            "Communicate a consistent image of the nurse as a leader.": 7,
            "Recognize the importance of nursing’s contributions as leaders in practice and policy issues": 8,
            "Provide leadership to advance the nursing profession.": 9,
            "Influence intentional change guided by leadership principles and theories.": 10,
            "Evaluate the outcomes of intentional change.": 11,
            "Evaluate strategies/methods for peer review.": 12,
            "Participate in the evaluation of other members of the care team.": 13,
            "Demonstrate leadership skills in times of uncertainty and crisis.": 14,
            "Advocate for the promotion of social justice and eradication of structural racism and systematic inequity in nursing and society.": 15,
            "Advocate for the nursing profession in a manner that is consistent, positive, relevant, accurate, and distinctive.": 16
        },
    },
}; 

export const CharacterRoles = {
    PATIENT: "Patient",
    DOCTOR: "Doctor",
    FAMILY_MEMBER: "Family Member"
 }